export default {

    methods:{

        /* 触发 */
        trigger(item:LibData, index:number,login:boolean=false):void{
            if (item.trigger) {

                if ((item.login || login) ) return ;

                return item.trigger.trigger(item,index,this);
            }
        }

    }

}
