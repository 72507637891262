<template>
  <div v-show="contentModalStatus"  class="notice-container rowACenter"
    :class="{
      'notice-container-hide': !realContent
    }"
  >
    <svg t="1657431585983" class="notice-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3098" width="200" height="200"><path d="M48 324.8S44.8 304 68.8 304H224S387.2 128 545.6 68.8h57.6v828.8H544s-180.8-68.8-320-236.8H65.6S48 660.8 48 640V324.8z" p-id="3099" fill="#FF9143"></path><path d="M700.8 209.6l139.2-121.6s86.4-14.4 75.2 60.8c-54.4 60.8-140.8 139.2-140.8 139.2s-102.4 25.6-73.6-78.4zM769.6 668.8l137.6 137.6s20.8 89.6-57.6 83.2c-67.2-52.8-156.8-137.6-156.8-137.6s-33.6-104 76.8-83.2z" p-id="3100" fill="#FF9143"></path><path d="M739.2 464s-9.6-40 38.4-40h176s41.6-9.6 41.6 40-8 57.6-40 57.6H760s-27.2 9.6-20.8-57.6z" p-id="3101" fill="#FF9143"></path></svg>
    <div class="flex-1 overflow">
      <div class="notice-content-line-1" ref="content">
        <span ref="contentTarget">{{content}}</span>
      </div>
    </div>
    <span v-if="allButton" @click="openNoticeModal(content)" class="notice-all-button">全部</span>
    <svg @click="closeModal" t="1657431726590" class="notice-close-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4066" width="200" height="200"><path d="M511.232 438.8352L112.9984 40.6016A51.2 51.2 0 0 0 40.6016 112.9984L438.784 511.232 40.6016 909.4656a51.2 51.2 0 1 0 72.3968 72.448l398.2336-398.2848 398.2336 398.2848a51.2 51.2 0 1 0 72.448-72.448l-398.2848-398.2336 398.2848-398.2336A51.2 51.2 0 0 0 909.4656 40.6016L511.232 438.784z" p-id="4067" fill="#999999"></path></svg>
  </div>
</template>

<script>

import {nextTick} from "vue";
import  { PlayerMessage } from '@/utils/class/Player/const/index';

export default {
  name: "notice",
  data(){
    return {
      allButton:false,
      realContent:undefined,
      content:'',
      modal:true
    }
  },
  computed:{
    contentModalStatus(){
      return this.modal && this.content;
    }
  },
  watch:{
    content:{
      handler(){
        this.initContent();
      },
      immediate: true
    }
  },
  methods:{

    closeModal(){
      this.realContent = undefined;
      this.modal = false;
    },

    initContent(){
      this.realContent = undefined;
      this.modal = true;
      if (this.content) {
        nextTick(()=>{

          let width = this.$refs.content.offsetWidth;
          let realWidth = this.$refs.contentTarget.offsetWidth;
          this.allButton = realWidth>=width;
          this.realContent = this.content;

        });
      } else {
        this.closeModal();
      }

    },

    setNoticeResponse(data){
      data = data || {};
      this.content = data.state === 1 ? data.content : '';
    }

  },
  inject:['getSocket','addLoginCallback','openNoticeModal'],
  mounted() {
    this.player = this.getSocket();

    this.addLoginCallback(()=>{
      this.player.getNotice().then((data)=>  data.errcode === 1000 && this.setNoticeResponse(data.data))
    });

    this.player.on(PlayerMessage.notice,(data)=>this.setNoticeResponse(data));

  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
