import {PlayerMessage} from '$utils/class/Player/const';
import popup from "$utils/tool/popup";
export default {

    inject:['getSocket','hideExpressionModal','disabledChatTaboo'],

    methods:{
      sendExpression(item){

          if (this.loading) return ;

          this.agoraRTMIM.send({
              message:item.id
          },PlayerMessage.chat).catch((fail)=>{
              if (fail===102) {
                  popup.$toast('您已被禁言','error');
                  this.disabledChatTaboo();
              }
          });

          return this.hideExpressionModal();
      }
    },

    mounted() {
        // 聊天对象
        this.agoraRTMIM = this.getSocket();
    },

}
