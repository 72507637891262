<template>
  <div class="absolute queue-highlight-gift-container" ref="bullet">

    <div class="highlight-row"
      v-for="(item,index) in config.column"
      :key="item"
    >
      <transition
        name="slidRight"
      >
        <div
            class="highlight-item rowACenter"
            v-if="column[index]"
            :key="column[index].item.id"
        >
          <span>送出</span>
          <div class="flex-1 highlight-item-gift-name">
            <div>{{column[index].item.name}}</div>
          </div>
          <v-image :src="column[index].item.logo" class="highlight-item-icon" background-color="transparent"></v-image>
          <svg class="highlight-item-ride-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1708" width="200" height="200"><path d="M930.6 794.8c6.2 6.2 6.2 16.4 0 22.6L817.5 930.6c-6.2 6.2-16.4 6.2-22.6 0L523.3 659.1c-6.2-6.2-16.4-6.2-22.6 0L229.2 930.6c-6.2 6.2-16.4 6.2-22.6 0L93.4 817.5c-6.2-6.2-6.2-16.4 0-22.6l271.5-271.5c6.2-6.2 6.2-16.4 0-22.6L93.4 229.1c-6.2-6.2-6.2-16.4 0-22.6L206.5 93.4c6.2-6.2 16.4-6.2 22.6 0l271.5 271.5c6.2 6.2 16.4 6.2 22.6 0L794.8 93.4c6.2-6.2 16.4-6.2 22.6 0l113.1 113.1c6.2 6.2 6.2 16.4 0 22.6L659.1 500.7c-6.2 6.2-6.2 16.4 0 22.6l271.5 271.5z" p-id="1709" data-spm-anchor-id="a313x.7781069.0.i0" class="" fill="#2a90ec"></path></svg>
          <span class="highlight-item-number">{{column[index].item.sendNumber || 1}}</span>
        </div>
      </transition>

    </div>

  </div>
</template>

<script>
import mixins from '../mixins';
import VImage from "$components/v-image/lib/main";
export default {
  name: "queue-highlight-gift",
  components: {VImage},
  mixins
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
