<template>
  <div class="swiper-container tab-container" ref="swiper">
    <div class="swiper-wrapper">
        <slot></slot>
    </div>
    <div ref="pagination" v-if="pagination" class="swiper-pagination" ></div>
  </div>
</template>

<script>
import 'swiper/css/swiper.min.css';
import props from '../props';
import Swiper from 'swiper';
export default {
  name: "swiper",
  emits:['change'],

  watch:{
    value:function () {

      if (this.swiper && this.value !== this.swiper.realIndex) {
        return this.slideTo(this.value);
      }

    }
  },

  methods:{
    installSwiper(){
      if (this.$refs.swiper) {

        let options = {
          initialSlide: this.value || 0,
          ...(this.swiperOption || this.$attrs),
          on:{
            slideChange: ()=> this.change()
          }
        };

        if (this.pagination) {
          if (options.pagination === undefined) options.pagination = {};

          options.pagination.el = this.$refs.pagination;
        }


        return new Swiper(this.$refs.swiper,options);
      } else {
        return  undefined;
      }
    },
    next(){
      return this.swiper && this.swiper.slideNext();
    },
    prev(){
      return this.swiper && this.swiper.slidePrev();
    },
    slideTo(index,speed) {
      return this.swiper && this.swiper.slideTo(index,speed);
    },
    update(){
      return this.swiper && this.swiper.update();
    },
    change(){
      if (this.swiper) {
        let index = this.swiper.realIndex;
        if (index !== this.value) {
          this.swiper && this.$emit('update:value',index);
          return this.swiper && this.$emit('change',index);
        }
      }

    }
  },
  mounted() {
    this.swiper = this.installSwiper();
  },

  props

}
</script>

<style scoped lang="scss" src="../style.scss"></style>
