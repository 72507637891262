<template>
  <scroll-view style="background-color: #F6F6F6">
    <div class="video-group-list rowACenter wrap"
      :class="{
      'video-group-list-padding': vid
      }"
    >
      <template
          v-for="(item) in videoList"
      >
        <div class="video-item"
             :class="{
          'video-item-active': vid === item.video_id
           }"
             v-if="item.video_id"
             :key="item.video_id"
             @click="playVideo(item)"
        >
          <v-image :src="item.video_thumb" class="video-item-image">
            <div class="video-item-time">{{item.duration}}</div>
            <div class="video-item-active-modal center" v-if="vid === item.video_id">
              <div class="video-item-play rowCenter">
                <img src="../images/play.gif" class="video-item-play-icon" />
                <span>播放中</span>
              </div>
            </div>
          </v-image>
          <div class="video-title">{{item.video_name}}</div>
        </div>
      </template>
    </div>
    <div v-if="vid" @click="cancelVideo" class="video-list-cancel-button button center">取消播放</div>
  </scroll-view>
</template>

<script>
import { inject,computed,ref,watch } from 'vue';
import props from '../props';
import VImage from "$components/v-image/lib/main";
import ScrollView from "$components/scroll-view/src/main";
export default {
  name: "video-list",
  components: {ScrollView, VImage},
  props,
  setup(props){
    const injectSocket = inject('getSocket');
    const setMobileLiveStatus = inject('setMobileLiveStatus');
    const createPlayLiveVideo = inject('createPlayLiveVideo')
    const socket = injectSocket();

    const vid = ref(undefined);

    const videoList = computed(()=>{

      return props.item.videos.map((item)=>{

        let time = [];

        let duration = Math.floor(item.video_duration/1000);

        time[0] = Math.floor(duration / 60 / 60);
        time[1] = Math.floor(duration / 60 % 60);
        time[2] = Math.floor(duration % 60);


        return {
          ...item,
          duration:time.filter((item)=> item >0).map((item)=>  item>=10 ? item : '0'+item).join(':')
        };
      })
    });

    const playVideo = function (item) {
      if (vid.value === item.video_id) return;
      vid.value = item.video_id;
      socket.playVideo({
        video_id: item.video_id,
        image: item.video_thumb
      });
      setMobileLiveStatus();
    }

    const cancelVideo = function () {
      createPlayLiveVideo();
      vid.value = undefined;
    }

    return {
      videoList,
      playVideo,
      props,
      vid,
      cancelVideo
    }

  }
}
</script>

<style scoped src="../style.scss" lang="scss"></style>
