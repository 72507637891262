export default {

    giftData:{
        type:Array,
        default:function () {
            return [];
        }
    },

    rankData:{
        type:Array,
        default:function () {
            return [];
        }
    }

}
