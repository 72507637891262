<template>
  <scroll-view
    :scrollY="$store.state.config.mobile"
  >
    <div
        class="bulletin-board-mobile bulletin-board-mobile-submit"
    >
      <!--  视图为空  -->
      <div class="bulletin-empty center"
           v-if="statusConst.empty === status"
      >
        <img src="../images/empty.png" class="bulletin-empty-icon" />
        <div>暂无问卷需要作答</div>
      </div>
      <!--  视图  -->
      <div v-else-if="statusConst.submit === status" class="bulletin-submit">
        <div class="bulletin-submit-bottom-line">
          <div class="bulletin-submit-title"
               :style="{
                'bulletin-submit-title-margin': data.desc
               }"
               v-if="data.name"
          >{{data.name}}</div>
          <div class="bulletin-submit-content" v-if="data.desc">{{data.desc}}</div>
        </div>
        <div class="bulletin-subject-group">
          <div
              v-for="(item,index) in subject"
              :key="'subject-'+index"
              class="bulletin-subject-item"
          >
            <div class="bulletin-subject-item-title">{{item.subjectIndex?(item.subjectIndex+'. '):''}}{{item.name}}{{item._config.content === false ? '':'('+(item._config.title)+')'}}<i v-if="item.must">*</i></div>

            <!-- 多行问答 -->
            <textarea
                class="bulletin-questionAnswer-input bulletin-questionAnswer-textarea"
                placeholder="请输入"
                maxlength="600"
                v-model="item.value"
                v-if="SubjectType.textarea === item.type || SubjectType.questionAnswer === item.type"
            >

            </textarea>

            <!--  选择  -->
            <div class="bulletin-select-group rowWrap"
                 v-else-if="SubjectType.question === item.type"
            >
              <a-radio-group
                :value="item.value"
                v-if="!item.multi_select"
              >
                <a-radio
                    v-for="(cItem,key) in item.options"
                    :key="'subject-type-'+key"
                    :value="key"
                    class="bulletin-select-item"
                    @change="changeTargetValue(item,{
                      key,
                      value:cItem
                    })"
                >{{key}}、{{cItem}}</a-radio>
              </a-radio-group>
              <a-checkbox-group
                  v-else
                  :value="item.value"
              >
                <a-checkbox
                    v-for="(cItem,key) in item.options"
                    :key="'subject-target-type-'+key"
                    :value="key"
                    class="bulletin-select-item"
                    @change="changeTargetValue(item,{
                      key,
                      value:cItem
                    })"
                >{{key}}、{{cItem}}</a-checkbox>
              </a-checkbox-group>
<!--              <div-->
<!--                  v-for="(cItem,key) in item.options"-->
<!--                  :key="'subject-type-'+key"-->
<!--                  class="bulletin-select-item rowACenter"-->
<!--                  :class="{-->
<!--                'bulletin-select-item-active': item.valueObject && item.valueObject[key],-->
<!--                [item._config.active]: item.valueObject && item.valueObject[key]-->
<!--              }"-->
<!--                  @click="changeTargetValue(item,{-->
<!--                key,-->
<!--                value:cItem-->
<!--              })"-->
<!--              >-->
<!--                <div class="bulletin-select-item-label">{{key}}</div>-->
<!--                <div class="flex-1"><div class="line-1">{{cItem}}</div></div>-->
<!--              </div>-->
            </div>

            <!--  问答  -->
            <div class="bulletin-questionAnswer jCenter"
                 v-else
            >
              <input
                  class="bulletin-questionAnswer-input"
                  placeholder="请输入"
                  maxlength="200"
                  v-model="item.value"
              />
            </div>

            <!-- 回答内容 -->
            <textarea
                class="bulletin-questionAnswer-input bulletin-questionAnswer-textarea"
                :class="{
                  'bulletin-questionAnswer-margin-top':item.allow_fill_by_self
                }"
                placeholder="请输入"
                maxlength="600"
                v-model="item.allow_fill_by_self_value"
                v-if="item.allow_fill_by_self"
            >

            </textarea>
          </div>
        </div>
        <a-button class="bulletin-button button-active " style="display: block" :loading="submitLoading" @click="submit" type="primary">提交</a-button>
      </div>
      <!--  参与成功  -->
      <div v-else-if="statusConst.success === status" class="bulletin-success center">
        <svg t="1652668196173" class="bulletin-success-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2087" width="200" height="200"><path d="M512 981.333333C252.8 981.333333 42.666667 771.2 42.666667 512S252.8 42.666667 512 42.666667s469.333333 210.133333 469.333333 469.333333-210.133333 469.333333-469.333333 469.333333z m-50.432-326.101333L310.613333 504.32a32 32 0 0 0-45.226666 45.226667l174.72 174.762666a32.341333 32.341333 0 0 0 0.341333 0.341334l0.256 0.213333a32 32 0 0 0 50.048-6.144l337.450667-379.605333a32 32 0 1 0-47.872-42.496l-318.762667 358.613333z" fill="#1D9C89" p-id="2088"></path></svg>
        <div>问卷提交成功，感谢您的参与！</div>
      </div>
    </div>
  </scroll-view>

</template>

<script>
import mixins from '../mixins';
import ScrollView from "$components/scroll-view/src/main";
import { Button,Radio,Checkbox } from 'ant-design-vue';
export default {
  name: "bulletin-board-target",
  components: {ScrollView,
    [Button.name]:Button,
    [Radio.name]:Radio,
    [Radio.Group.name]:Radio.Group,
    [Checkbox.name]:Checkbox,
    [Checkbox.Group.name]:Checkbox.Group
  },
  mixins
}
</script>

<style scoped src="../style.scss" lang="scss">

</style>
