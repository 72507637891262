export default {

    barrage:{
        type:Boolean,
        default:true
    },

    background:{
        type:String,
        default:''
    },

    liveStatus:{
        type:Boolean,
        default:false
    },

    loading:{
        type:Boolean,
        default:true
    },

    countDown:{
        type:Object,
        default:function () {
            return {};
        }
    },

    userInfo:{
        type:Object,
        default:function () {
            return {};
        }
    },

}
