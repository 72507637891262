enum Status {

    none,

    loading,

    success,

    fail

}

export {
    Status
}
