export default {

    unit(size:ImageExport,unit:string='px',units:Array<string>=['width','height','top','left','right','bottom']):ImageExport{

        units.map((item:string)=> {
             // @ts-ignore
            if (typeof size[item] ==='number') {
                // @ts-ignore
                size[item] += unit;
            }

        });

        return size;

    }

}
