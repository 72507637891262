<template>
  <div class="give-page">
    <img @touchstart="addGiveIconList" @touchend="addGive" src="../images/live.png" class="give-live-icon" />
    <div v-if="likeItem.show_num" class="center give-number">{{countFormat}}</div>
    <div class="thumb">
      <div class="thumb-item"
           v-for="(item,index) in giveIconList"
           :key="index"
           :class="['line-'+item.line]"
      >
        <img :src="item.icon" />
      </div>
    </div>
  </div>
</template>

<script>
import {reactive, inject, ref, computed,onBeforeUnmount} from 'vue';
const iconsContext = require.context('../images/icons/',false,/.*\.png/);
import props from '../props';
export default {
  name: "give",
  props,
  setup(props){

    let giveIconList = reactive({});

    const image = {};

    const injectSocket = inject('getSocket');

    const closeLikeModal = inject('closeLikeModal');

    const configId = props.item.config_id;

    const likeItem = reactive({
      ...props.item
    })

    const countFormat = computed(()=> {

      let count = likeItem.like_count;

      if (count >= 10000) {
        // 查找单位
        let countEm = '';
        let countFormat = '';
        let target = 10000;
        let format = ['万','亿'];
        format.map((item)=>{
          if (Math.floor(count / target) > 0) {
            countEm = item;
            countFormat = count / target;

            if (countFormat !== Math.floor(countFormat)) {
              countFormat = countFormat.toFixed(1);
            }
            countFormat+=item;
            target *= 10000;
          }

        });
        if (countFormat) {
          return  countFormat;
        }

      }

      return count;
    })

    iconsContext.keys().map((item)=>{
      image[item.split('/').pop().split('.').shift()] = iconsContext(item);
    })

    let line = 1;

    const addGiveIconList = function () {
      const length = Object.keys(giveIconList).length;
      const unique = +new Date() + '-' + Math.floor(Math.random() * 100000000) + '-'+length;

      giveIconList[unique] = {
        time:undefined,
        icon: image[Math.ceil(Math.random() * 3)+'-'+line],
        line:line++
      }

      if (line >=4) {
        line = 1;
      }

      giveIconList[unique].time = setTimeout(()=>{
        clearTimeout(giveIconList[unique].time);
        delete giveIconList[unique];
      },1500);

    }

    let time;
    let disabled= false;

    const socket = injectSocket();


    const addGive = function () {
      addGiveIconList();
      // if (disabled) return;
      // disabled = true;
      // time = setTimeout(()=>{
      //   clearTimeout(time);
      //   disabled = false;
      // },300);

      likeItem.like_count++;
      return socket.give(configId).then(()=> {
        reCount()
      });
    }

    let reCountLoading = false;
    const reCount = function () {
      if (reCountLoading) return;
      reCountLoading = true;
      socket.getLickCount().then((data)=>{
        reCountLoading = false;
        let resultData = data.data || {};
        if (resultData.like_count > likeItem.like_count) {

          let diff =Math.min((resultData.like_count - likeItem.like_count),30);

          let timeArray = [];

          for (let i=0;i<diff;i++) {
            timeArray[i] = setTimeout(()=>{
              addGiveIconList();
            },i * 100);
          }

          likeItem.like_count = resultData.like_count;
        }
        if (likeItem.show_num !== resultData.show_num) {
          likeItem.show_num = resultData.show_num;
        }
        if (resultData.is_open === 0) {
          closeLikeModal();
        }
      }).catch(()=>{
        reCountLoading = false;
      });
    }

    let countTime = setInterval(()=>{
      addGiveIconList();
      reCount();
    },3000);

    onBeforeUnmount(()=> clearInterval(countTime));

    return {
      giveIconList,
      addGiveIconList,
      addGive,
      countFormat,
      likeItem
    }

  }
}
</script>

<style scoped src="../style.scss" lang="scss"></style>
