export default {

    // 摇晃
    'bottom':{
        duration:4000,
        animated:true
    },

    'left':{
        duration:4000,
        animated:true
    },

    'center':{
        duration:3000,
        animated:true
    }

}

export const AnimateType = {
    // 中心缩放
    3:'center',
    // 从上到下
    2:'bottom',
    // 从左到右
    1:'left'
}
