export default class Notice {

    triggers:Record<string, any>={};

    on(key:any,callback:Function) {

        if (!this.triggers) return ;
        if (!callback) return;

        if (this.triggers[key] === undefined) this.triggers[key] = [callback];
        else this.triggers[key].push(callback);

        return this;

    }

    off(key:any,callback?:Function) {

        if (!this.triggers) return ;

        if (this.triggers[key] === undefined) return;

        if (callback) {
            let index = this.triggers[key].indexOf(callback);

            if (index>=0) this.triggers[key].splice(index,1);
        } else {
            delete this.triggers[key];
        }



        return this;

    }

    triggerAllListener(data){
        if (!this.triggers) return ;
        let keys = Object.keys(this.triggers);
        return keys.map((key)=>{
            return this.triggerListener(key,data);
        });
    }

    triggerListener(key:any,data?:any) {
        if (!this.triggers) return ;
        if (this.triggers[key]) {
            this.triggers[key].map((item)=> item && item(data));
        }

    }

    destroy(){
        // 清空所有监听事件
        this.triggers = null;
    }

}
