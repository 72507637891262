export default {

    // 判断是否为object
    isObject(data:any):boolean{
        return  Object.prototype.toString.call(data) === '[object Object]';
    },

    // 判断是否为array
    isArray(data:any):boolean {
        return  Object.prototype.toString.call(data) === '[object Array]';
    }

}