import {PlayerMessage} from "$utils/class/Player/const";

export default {

    data(){
        return {
            controlRole:{
                vote:false,
                question:false,
                gift:true,
                like:false
            },
            likeItem:{}
        }
    },

    computed:{
      controlShow(){
          return ['vote','question','gift'].some( (item)=>{
              return this.controlRole[item];
          })
      }
    },

    provide(){
        return {
            closeQuestionnaire:()=> this.closeQuestionnaire(),
            disabledChatTaboo:()=> this.disabledChatTaboo(),
            closeLikeModal:()=> this.closeLikeModal()
        }
    },

    methods:{

        disabledChatTaboo(){
            return this.$refs['chat'] && this.$refs['chat'].setTaboo(true);
        },

        closeLikeModal(){
            this.controlRole.like = false;
        },

        closeQuestionnaire(){
            this.controlRole.vote = false;
            this.voteModal = false;
        },

        installControlRole(){
            // 展示投票
            this.controlRole.vote = this.player.getVoteRole();

            this.player.on(PlayerMessage.vote, (data)=> {
                this.controlRole.vote = data.status;
                if (!data.status) {
                    this.closeQuestionnaire();
                }
            })

            this.player.on(PlayerMessage.question, (data)=> {
                if (data.status === false) {
                    this.controlRole.question = false;
                    this.bulletinBoardModal = false;
                }
            });
        },

        installBeforeControlRole(){
            this.player.getLickCount().then((data)=>{
                let resultData:Record<string, any> = data.data || {};
                this.controlRole.like = !!resultData.is_open;
                this.likeItem = resultData;
            });

            this.player.getGiftConfig().then((data)=>{
                this.controlRole.gift = !!data.data.status;
            })

            this.player.getQuestion().then((data)=>{
                this.controlRole.question = !!data.page_in[0];
            });
        }

    }

}
