export default {

    item:{
        type:Object,
        default:function () {
            return {};
        }
    },

    loading:{
        type:Boolean,
        default:false
    }

}
