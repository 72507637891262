export default {

    // 转换加载状态时状态时间
    asyncTime:{
        type:Number,
        default:2000
    },

    // 是否存在分页
    paging:{
        type:Boolean,
        default: true
    },

    // 底部占位尺寸
    occupancy:{
        type:Number,
        default:0
    },

    // 页码数量
    pageSize:{
        type:Number,
        default:10
    },

    // 兼容的滚动条安全尺寸
    securitySize:{
        type:Number,
        default:0
    },

    // 是否创建索引id
    indexes:{
      type:String,
      default:undefined
    },

    emptyColor:{
      type:String,
     default:'#666'
    },

    // 距离底部多远触发
    lowerThreshold:{
        type:Number,
        default:200
    },

    // 首次是否触发
    first:{
        type:Boolean,
        default: true
    },

    // 为空的提示文本
    emptyText:{
        type:String,
        default:'暂无数据'
    }

}
