<template>
  <img src="../images/loading.gif" class="v-loading" />
</template>

<script>
export default {
name: "v-loading"
}
</script>

<style scoped lang="scss">
.v-loading {
  width: 300px;
  height: 225px;
}
</style>
