import $config from '$config/index';
export default {

    addBaseURL(url:string,baseURL:string=$config.baseURL):string{

        if (!url) return url;

        if (url.indexOf('data:') < 0 && url.indexOf('http') < 0) {
            url = baseURL + url;
        }
        return url;

    }

}
