<template>
    <div class="modal flex"
         :class="['modal-'+(vValue?'show':'hide'),'modal-'+($store.state.config.mobile ? 'mobile':'pc')]"
         :style="{backgroundColor:live?'transparent':backgroundColor}"
    >
      <div v-if="live" class="modal-live-height" @click="close"></div>
      <div class="flex-1" @click="close" v-else></div>
      <div class="modal-content"
        :class="['modal-content-'+(vValue?'show':'hide'),live?'flex-1':'',title?'modal-content-title':'']"
           :style="{height:height}"
      >
        <header v-if="title" class="modal-live-header">
          <div class="rowACenter">
            <div class="flex-1">
              <div>{{title}}</div>

            </div>
            <svg @click="close" class="modal-live-close-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2468" width="200" height="200"><path d="M511.232 438.8352L112.9984 40.6016A51.2 51.2 0 0 0 40.6016 112.9984L438.784 511.232 40.6016 909.4656a51.2 51.2 0 1 0 72.3968 72.448l398.2336-398.2848 398.2336 398.2848a51.2 51.2 0 1 0 72.448-72.448l-398.2848-398.2336 398.2848-398.2336A51.2 51.2 0 0 0 909.4656 40.6016L511.232 438.784z" p-id="2469" fill="#999999"></path></svg>
          </div>
          <div class="modal-live-line"></div>
        </header>
        <slot>
          <div class="center modal-default-content">测试</div>
        </slot>
      </div>
    </div>
</template>

<script>
import props from '../props';
export default {
  name: "modal",
  props,

  data(){
    return {
      vValue:false
    }
  },

  watch:{
    value:{
      handler(value){
        this.changeValue(this.value);
      },
      immediate:true
    }
  },

  methods:{

    changeValue(value){
      if (this.vValue !== value) {
        this.vValue = value;
      }
      if (this.value !== value) {
        return this.$emit('update:value',value);
      }
    },

    close(){
      return this.changeValue(false);
    }

  }

}
</script>

<style scoped lang="scss" src="../style.scss"></style>
