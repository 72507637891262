export default function () {
    return [
        {
            label:'礼物',
            component:'gift',
            slot:'tab-0'
        },
        {
            label:'排行',
            component:'gift-ranking',
            slot:'tab-1'
        }
    ]
}
