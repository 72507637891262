import {FlatListReload} from "$components/flat-list/const";

export default {

    inject:['getSocket'],

    provide(){
      return {
          openGiftDetail:(giftItem)=> this.openDetail(giftItem)
      }
    },

    data(){
        return {
            pageStatus:false,
            giftItem:{}
        }
    },

    methods:{

        openDetail(item){

            if (this.giftItem.id !== item.id || item.count !== this.giftItem.count) {
                this.giftItem = JSON.parse(JSON.stringify(item));
                this.$refs.flat && this.$refs.flat.reload(FlatListReload.start);
            }


            if (!this.pageStatus) this.pageStatus =true;



        },

        closeDetail(){
            if (this.pageStatus) this.pageStatus =false;
        },

        fetchGiftDetail(obj){

            let pageOption = obj.data;
            pageOption.id = this.giftItem.id;

            this.agoraRTMIM.getGiftSendDetail(pageOption).then((data)=>{

                return obj.success((data.data || {}).rank_list || []);

            }).catch(obj.fail);

        }

    },

    mounted() {
        // 聊天对象
        this.agoraRTMIM = this.getSocket();

    }

}
