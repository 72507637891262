export enum Status {
    empty,
    success,
    submit
}


export enum SubjectType {
    question='question',
    questionAnswer='questionAnswer',
    input='input',
    textarea='textarea'
}
