import popup from "./popup";

export default {

    setupWebViewJavascriptBridge(callback) {
        if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge); }
        if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback); }
        window.WVJBCallbacks = [callback];
        var WVJBIframe = document.createElement('iframe');
        WVJBIframe.style.display = 'none';
        WVJBIframe.src = 'https://__bridge_loaded__';
        document.documentElement.appendChild(WVJBIframe);
        setTimeout(function() { document.documentElement.removeChild(WVJBIframe) }, 0)
    },

    // 执行app 方法
    triggerApp(name,params){
        try {
            var u = navigator.userAgent;
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);

            if(isiOS) {
                this.setupWebViewJavascriptBridge(function (bridge) {
                    bridge.callHandler(name,params);
                })
            } else {
                if (window.WebViewJavascriptBridge) {
                    window.WebViewJavascriptBridge.callHandler(name,params);
                }
            }

            //  else {
            //     popup.$toast('执行,bridge'+':'+(window.bridge?'存在':'不存在'));
            //     window.bridge.callHandler(name,params);
            // }

        } catch (e) {
            console.log(e);
            // popup.$toast(e.message);
        }
    },

    // 跳转专家详情
    routerExpertDetails(link){
        return this.triggerApp('boaoLink',{link});
    }

}
