import {PlayerMessage} from "$utils/class/Player/const";

export default {

    data(){
        return {
            queueTime:0
        }
    },

    inject:['getSocket'],

    methods:{

        // 增加可播放动画
        pushGift(item) {
            return this.pushQueue(item);
        }

    },

    mounted() {
        // 聊天对象
        this.agoraRTMIM = this.getSocket();

        this.agoraRTMIM.on(PlayerMessage.gift,({data})=>{
            return this.pushGift(data);
        })
    },

    beforeUnmount() {
        this.agoraRTMIM.off(PlayerMessage.gift);
    }


}
