export default {


    // 是否存在成功状态
    success:{
        type:Boolean,
        default:true
    },

    // 加载成功的文本
    successText:{
      type:String,
      default:'成功'
    },

    // 成功的颜色
    successColor:{
      type:String,
      default:'#00D5AF'
    },

    // 触发change-success的时间
    changeSuccessTime:{
      type:Number,
      default:1000
    },

    // 失败状态的文本
    failText:{
      type:String,
      default:'服务繁忙,点击重试'
    },

    // 失败的颜色
    failColor:{
      type:String,
      default:'#ff3a29'
    },

    // 成功状态后的延迟
    asyncSuccess:{
        type:Number,
        default:3000
    },

    // 是否可以进入加载状态
    intoLoading:{
        type:Boolean,
        default: true
    },

    // 加载状态是否改变size
    loadingSize:{
        type:Boolean,
        default: true
    },

    // 禁用按钮
    disabled:{
        type:Boolean,
        default:false
    }


}