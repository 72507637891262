export default {

    userInfo:{
        type:Object,
        default:function () {
            return {};
        }
    },

    loading:{
        type:Boolean,
        default: false
    }

}
