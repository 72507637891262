/**
 * @return {string}
 */
const DateFormat = function (date,format='YYYY-MM-DD hh:mm:ss') {

	if (typeof date !== 'object' || date === null) {

		if (/^\d+$/.test(date)) {

			if (String(date).length <= 10) {
				date = date +'000';
			}
			date = new Date(Number(date));
		} else if (typeof date === 'string') {

			if (date.indexOf('-') >= 0) date = date.replace(/-/g,'/');

			date = new Date(date);
		} else {
			date = new Date();
		}

	}


	return format.replace(DateFormat.reg,function (name) {

		let target = DateFormat.params[name[0]];
		if (target) {
			if (name === 'YYYY') {

				if (date instanceof Date) {
					return date[target]();
				} else {
					return date[DateFormat.formatParams[name[0]]];
				}

			} else {

				let value;
				if (date instanceof Date) {
					value = date[target]();

					if (DateFormat.target[name[0]]) {
						value += DateFormat.target[name[0]];
					}
				} else {
					value = date[DateFormat.formatParams[name[0]]];
				}

				if (name.length >= 2) {

					value = value <10?'0'+value:value;

				}

				return value;

			}
		} else {
			return name;
		}



	});

}

DateFormat.inverse = function (date:string | Date,format:string='YYYY-MM-DD hh:mm:ss'):Record<string, any> {

	let resultObject =  {};
	format.replace(DateFormat.reg,function (name,start) {

		let useName = DateFormat.formatParams[name[0]];

		if (date instanceof Date) {

			let value =parseInt(date[DateFormat.params[name[0]]]());
			if (DateFormat.target[name[0]]) {
				value += DateFormat.target[name[0]];
			}

			resultObject[useName] = value;
		} else {
			resultObject[useName] = parseInt(date.substr(start,name.length));
		}

		return '';


	});

	return resultObject;

}

DateFormat.createMaxDate = function (date?:Date) {

	if (date === undefined ) date = new Date();

	return new Date(date.getFullYear()+'/12/31 23:59:59');

}

DateFormat.createMinDate = function (date?:Date) {

	if (date === undefined ) date = new Date();

	return new Date(date.getFullYear()+'/1/1 00:00:00');

}



DateFormat.formatParams = {
	'Y':'year',
	'M':'month',
	'D':'date',
	'h':'hour',
	'm':'minute',
	's':'second'
};


DateFormat.params = {
	'Y':'getFullYear',
	'M':'getMonth',
	'D':'getDate',
	'h':'getHours',
	'm':'getMinutes',
	's':'getSeconds'
};
/* 递增 */
DateFormat.target = {
	'M': 1
};

DateFormat.reg = /YYYY|M{1,2}|D{1,2}|h{1,2}|m{1,2}|s{1,2}/g;

export default 1;

export {
	DateFormat
}
