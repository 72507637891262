import config from '$config/config';

import constName from './const';

export default <LibRequestConfig>{

    [constName.mode.default] :{
        baseURL:'https://boao.yunkust.com/',
        // baseURL:'/api/',

        method:'POST',

        headers:{
            'content-type':'application/x-www-form-urlencoded; charset=UTF-8'
        },
    },

    [constName.mode.main] :{
        baseURL:config.baseURL + config.baseApiURL,
        // baseURL:'/api/',

        method:'POST'
    },

}
