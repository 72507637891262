export default {

    item:{
        type:Object,
        default:function (){
            return {}
        }
    },

    type:{
        type:[String,Number],
        default:'0'
    },

    isMy:{
        type:Boolean,
        default:false
    },

    top:{
        type:Boolean,
        default:false
    },

}
