// 开放接口
import Status from "$mixins/status/const/status";
import {FlatListReload} from "$components/flat-list/const";

export default <LibMixins>{



    methods:{

        // 当前是否正在执行状态
        flat_is_loading(){
            return this._status === this.constStatus.loading;
        },

        flat_clear_to_start(){
          if (this.data.length > 0 || this.status!==this.constStatus.default) {
              // 控制为 无状态
              this.status = this.constStatus.default;
              this._status = this.constStatus.default;
              this.setStart(true);
              return this.setData([],true);
          }
        },

        flat_delete(id:number | string){
            id = this.flat_get_index(id);
            if (id >= 0) {
                this.data.splice(id,1);
                this.indexes && this.dataIndexes.splice(id,1);

                this.$emit('changeData',this.data);

                if (this.data.length <= 0) {
                    return this.reload(FlatListReload.start);
                } else if (this.hasPaging && this.data.length < this.pageSize) {
                    return this.reload(FlatListReload.next);
                }

            }
        },

        flat_update(id:number | string,item:Record<string, any>){
            id = this.flat_get_index(id);

            if (id >= 0) {
                this.data[id] = item;
            }
        },

        flat_get_data(id:number | string){
            return this.data[this.flat_get_index(id)]
        },

        flat_unshift(id:number | string,item:Record<string, any>){
            if (id) {
                if (this.start) {
                    this.setStatus(Status.success,[item]);
                } else {

                    if (this.indexes && this.dataIndexes.indexOf(id) >= 0) return;

                    this.data.unshift(item);
                    this.indexes && this.dataIndexes.unshift(id);
                    return this.$emit('changeData',this.data);
                }
            }
        },

        flat_push(id:number | string,item:Record<string, any>){
            if (id) {
                if (this.start) {
                    this.setStatus(Status.success,[item]);
                } else {
                    if (this.indexes && this.dataIndexes.indexOf(id) >= 0) return;
                    this.data.push(item);
                    this.indexes && this.dataIndexes.push(id);
                    return this.$emit('changeData',this.data);
                }
            }
        },

        flat_get_index(index:number | string){
            if (this.indexes) {

                let useIndex = this.dataIndexes && this.dataIndexes.indexOf(index);

                return useIndex === undefined ? -1 : useIndex;
            } else {
                return index;
            }
        }

    }


}
