<template>
  <div class="row stepper overflow"
    :class="['steeper-'+type]"
  >
    <aside class="center stepper-control"
      :class="{
        'cursor-pointer': vValue > min
      }"
           @click="trigger(false)"
    >
      <icon type="reduce" class="stepper-control-icon stepper-control-reduce-icon"></icon>
    </aside>
    <aside class="flex-1 overflow center stepper-content">{{vValue}}</aside>
    <aside class="center stepper-control"
      :class="{
        'cursor-pointer': vValue < max
      }"
           @click="trigger(true)"
    >
      <icon type="add" class="stepper-control-icon stepper-control-add-icon"></icon>
    </aside>
  </div>
</template>

<script>
import props from '../props';
import icon from '$components/icon';
export default {
  name: "stepper",

  data(){
    return {
      vValue:0
    }
  },

  watch:{
    value:{
      handler:function (){
        this.setValue(this.value);
      },
      immediate:true
    }
  },

  methods:{

    setValue(value){

      value = value <= this.min ? this.min : value;

      value = value >= this.max ? this.max : value;

      if (this.vValue !== value) {
        this.vValue = value;
        if (this.value !== value) {
          return this.$emit('update:value',value);
        }
      }
    },

    trigger(status){
      if (this.disabled) return ;
      return this.setValue(this.vValue + (status ? 1 : -1));
    }

  },
  props,
  components:{
    icon
  }

}
</script>

<style scoped lang="scss" src="../style.scss"></style>
