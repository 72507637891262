import Player from '$utils/class/Player/index';
import {createApp, nextTick} from 'vue';
import mobileLiveControl from '../components/mobile-live-control';
import user from '$config/user';
import {PlayerMessage} from "$utils/class/Player/const";
import popup from "$utils/tool/popup";
import config from '$config/index';
import md5 from 'spark-md5';
export default {

    data(){
      return {
          live_num:0,
          countDown:undefined,
          Themes:{
              name:'',
              live_img:'',
              banner:'',
              cover_img:''
          },
          loading:false,
          initLoading: false,
          draw:true,
          liveResultStatus:false,
          activeItem:{},
          failText:''
      }
    },

    provide(){
      return {
          toQuestionnaire:(data)=> {
             this.controlRole.question = true;
             if (data.dialog_mode === 1) {
                 // @ts-ignore
                 if (window.getTargetCookieKey('skip_signup_'+data.question_id) !== '1') {
                     this.bulletinBoardModal = true;
                 }
             }
          },
          addLoginCallback:(callback)=> {
              callback && this.loginCallbackMany.push(callback);
          },
          setMobileLiveStatus:()=> {
              this.setMobileLiveTargetStatus();
          },
          createPlayLiveVideo:()=> {
              this.createPlayLiveVideo();
          }
      }
    },

    methods:{

        installPlayer(first:boolean){

            this.loading = true;
            this.initLoading = true;

            // @ts-ignore
            this.player.init(window.channelId).then((data)=>{

                this.installBeforeControlRole();

                this.initLoading = false;
                let useUser = user.user;
                // 发送登录消息
                // if (useUser.login_time) {
                //     this.player.send({
                //         user:useUser
                //     },PlayerMessage.login);
                // }


                // this.Themes = {
                //     ...this.player.getThemes(),
                //     // @ts-ignore
                //     name: Mudu.Room.name
                // }
                // 渲染播放器
                this.createPlayLiveVideo();

                let roomInfo = this.player.getRoomInfo();
                this.Themes.name = roomInfo.name;
                this.Themes.live_img = roomInfo.live_img;
                try {
                    this.Themes.cover_img = roomInfo.themes[0].cover_img;
                    this.Themes.banner = roomInfo.themes[0].banner;

                } catch (e) {

                }
                this.changeFavicon(this.Themes.cover_img);

                // 获取自定义菜单栏
                this.setMenus(this.player.getMenus());

                // 获取观众人数
                this.live_num = this.player.getViewNum();

                // 获取倒计时
                this.countDown = this.player.getCountdown();

                // 设置获取历史记录
                nextTick(()=> {
                    if (this.$refs.chat) {
                        this.$refs.chat.getHistory();
                        setTimeout(()=>{
                            this.$refs.chat.setEmpty();
                        },5000);
                    }
                    this.loginCallbackMany && this.loginCallbackMany.map((callback)=>{
                        callback && callback();
                    });
                    this.loginCallbackMany = [];

                });

                // 获取礼物信息
                this.getGiftList();

                // 登录 sdk
                this.loginSDK();
            }).catch(()=>{

            });
        },

        createPlayLiveVideo(){

            this.player.remove();
            this.player.createPlayer({
                containerId:'player'
            },(config)=>{
                this.liveResultStatus = this.player.getLiveStatus();
                if (this.$store.state.config.mobile) {
                    nextTick(()=> this.installMobileControl());
                }
            });
        },

        changeFavicon(link){

            document.title = this.Themes.name;

            let $favicon = document.querySelector('link[rel="icon"]');
            if ($favicon !== null) {
                // @ts-ignore
                $favicon.href = link;
            } else {
                $favicon = document.createElement("link");
                // @ts-ignore
                $favicon.rel = "icon";
                // @ts-ignore
                $favicon.href = link;
                document.head.appendChild($favicon);
            }
        },

        // 登录
        loginSDK(){
            let userParams = {
                name:decodeURIComponent(config.GET.name),
                avatar: config.GET.avatar || 'https://static.mudu.tv/index/avatar.png',
                id: (config.GET.id|| ''),
                assign_id: (config.GET.assign_id || ''),
            }


            this.$store.commit('initializationUser',{
                email:'',
                now_time:0,
                ...userParams,
                login_time:10
            });

            this.setMenus(this.player.getMenus());

            setTimeout(()=>{
                this.setMenus(this.player.getMenus());
            },3000);

            // 设置用户登录
            return this.player.login(
                userParams.name,
                userParams.avatar,
                userParams.assign_id.toString()
            ).then((data)=>{

                this.installControlRole();

                this.userInfo = data.user;

                // this.player.send({
                //     message:'我加入房间-'+userParams.name
                // });

                nextTick(()=> this.loading = false);
                // 设置用户登录成功
                return this.setStatus(this.constStatus.success);
            });
        },

        changeTrigger(item){
            this.activeItem = item.item;
        },

        installMobileControl(){
            let divElement = document.createElement('div');
            divElement.setAttribute('id','player-control');
            document.getElementById('player').appendChild(divElement);
            this.mobileContent = createApp(mobileLiveControl).mount('#player-control');
            this.mobileContent.watchCallback((status)=>{
                this.barrage = status;
            });
            this.setMobileLiveStatus();
        },

        // 设置移动端播放视频状态
        setMobileLiveTargetStatus(){
            if (this.mobileContent) {
                this.liveResultStatus = true;
                this.mobileContent.setLiveStatusNumber(true,this.player.getViewNum());
            }
        },

        // 设置移动端状态
        setMobileLiveStatus(){
            if (this.mobileContent) {
                this.liveResultStatus = this.player.getLiveStatus();
                this.mobileContent.setLiveStatusNumber(this.player.getLiveStatus(),this.player.getViewNum());
            }
        }

    },

    mounted() {

        this.player.on(PlayerMessage.streamEvent,(data)=>{
            this.setMobileLiveStatus();
        });

        return this.draw && this.loginSuccess(true);
    },

    created(){
        this.loginCallbackMany = [];
        this.player = new Player();

        let order = ['assign_id'];

        // if (config.GET.id && config.GET.assign_id) {
        config.createdGET();

        let unique = '';

        if ( config.GET['assign_id']) {
            // @ts-ignore
            unique = md5.hash(order.map((item)=> config.GET[item] || '').join('')+(config.GET.authKey || ''));
        } else if (config.GET['userid']) {
            // @ts-ignore
            unique = md5.hash((config.GET.authKey || '')+['userid'].map((item)=> config.GET[item] || '').join(''));
        }
        console.log(config.GET);
        config.GET['assign_id'] = config.GET['assign_id'] || config.GET['userid'];

        if (config.GET['userid']) {

        } else {
            if (config.GET['assign_id'] && config.GET.key && unique !== config.GET.key) {
                this.failText = '参数错误:assign_id:'+ config.GET['assign_id']+'-密钥:'+config.GET.authKey+'-加密结果:'+unique+'-链接中key:'+config.GET.key;
                // 执行错误
                config.GET = {};

                // 格式校验错误
                // popup.$toast('参数错误','error');
                //
                this.draw = false;

                return;
            }
        }



        //  646894 636689
        // this.player.init(847618);
        this.player.on(PlayerMessage.login,({data})=>{
            let useUser = user.user;
            if (useUser && data.user && useUser.email === data.user.email) {
                if (useUser.login_time < data.user.login_time) {
                    this.$store.dispatch('outLogin');
                    this.player && this.player.remove();
                    return popup.$toast('账号在其他地方登录');
                }
            }

        });

    },

    beforeUnmount() {
        delete this.mobileContent;
        this.player.off(PlayerMessage.login);
    }

}
