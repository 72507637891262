import { nextTick } from 'vue';
export default <LibMixins>{

    data(){
      return {
          vUseTabData:undefined,
          vUseTabSelect:undefined,
          swipeOption:undefined,
          width:[]
      }
    },

    provide(){
        return {
            updateHeight:()=>{
                if (this.$refs.swiper) {
                    return this.$refs.swiper.swiper.updateAutoHeight();
                }
            }
        }
    },


    computed:{

        swiper(){
            if (this.$refs.swiper) {
                return this.$refs.swiper.swiper;
            }
          return undefined;
        },

        // 当前索引的下标
        vTabSelect:{
            get(this:any):number{

                if (this.vUseTabSelect === undefined) {
                    this.vUseTabSelect = this.value === undefined ? this.defaultValue : this.value;
                }

                return this.vUseTabSelect;

            },
            set(this:any,value:number) {
                if (this.vUseTabSelect !== value) {
                    this.vUseTabSelect = value;
                    if (this.vUseTabData && this.vUseTabData[value].loading !== true) {
                        this.vUseTabData[value].loading = true;
                    }

                    // 查看是否需要变更 swiper
                    if (this.swiper && this.swiper.realIndex !== value) {
                        this.swiper.slideTo(value,300);
                        this.updateAutoHeight();
                    }
                    // 执行change
                    if (this.value !== value)  {
                        this.$emit('change',{
                            item:this.vUseTabData[value],
                            value
                        });
                        return this.$emit('input',value);
                    }

                }
            }
        },

        // 当前使用的 data
        vTabData():Array<tabDataObject> {

            if (this.vUseTabData === undefined) {
                this.vUseTabData = (this.data as tabData).map((item,index):tabDataObject=>{

                    let result:tabDataObject;
                    if (typeof item === 'string') {
                        result = {
                            label: item,
                            slot:'',
                            loading: false
                        }
                    } else {
                        result = JSON.parse(JSON.stringify(item));
                    }

                    // 设置 loading
                    if (!result.loading) {
                        result.loading = !(index !== this.vTabSelect && this.lazy);
                    }

                    // 设置插槽
                    result.slot = result.slot || 'tab-'+index;

                    return result;

                });

                this.$emit('change',{
                    item:this.vUseTabData[this.vTabSelect],
                    value:this.vTabSelect
                });
            }

            return this.vUseTabData;

        },

        // line
        lineOffsetLeft(){

            let offsetLeft = 0;
            for (let i=0;i<this.vTabSelect;i++) {
                offsetLeft += this.width[i] || 0;
            }
            return offsetLeft;
        }

    },

    watch:{
      value:function (){
          return this.triggerTo(this.value);
      },
        data:{
          handler:function(){
              let width = [];
              nextTick(()=>{
                  this.vTabData.map((item,index)=>{
                      if (this.$refs['menus'+index]) {
                          width.push(this.$refs['menus'+index].offsetWidth);
                      }
                  });
                  this.width = width;
              })
          },
          immediate:true
        }
    },

    methods:{

        triggerTo(index:number){
            if (this.vUseTabSelect !== index) {
                this.vTabSelect = index;
                let el = this.$refs['menus'+index];
                let scroll = this.$refs.scroll;
                if (el && scroll) {
                    scroll.scrollTo({
                        x: el.offsetLeft,
                        duration:300
                    });
                }
            }
        },

        updateAutoHeight(){
            if (this.autoHeight) {

                nextTick(()=> {
                    this.swiper && this.swiper.updateAutoHeight();
                })

            }
        }
    },

    mounted() {

        this.updateAutoHeight();

    },

    created() {

        this.swipeOption = {
            initialSlide: this.vTabSelect,
            allowTouchMove: this.touchMove,
            speed: this.speed,
            autoHeight: this.autoHeight,
            resizeObserver: this.autoHeight,
            on:{
                slideChangeTransitionStart: (swiper: any)=>{
                    // 设置执行下标
                    this.vTabSelect = swiper.realIndex;
                }
            }
        }
    }


}
