import {PlayerMessage} from "$utils/class/Player/const";

export default {

    inject:['getSocket'],

    data(){
      return {
          countDownId:null,
          countDownIdFormat:[]
      }
    },

    watch:{
        countDown:function () {
            this.createdCountDown(this.countDown);
        },
        liveStatus:function () {
            if (this.liveStatus) {
                this.clearCountDown();
            } else {
                this.createdCountDown(this.countDown);
            }
        }
    },

    methods:{

        createdCountDown(countDown){

            let liveStatus = this.player.getLiveStatus();
            if (countDown && countDown.status && !liveStatus) {
                let countDownId = JSON.stringify(countDown);
                if (this.countDownId !== countDownId) {
                    this.countDownConfig = {
                        endTime: +new Date(countDown.start_time.replace(/-/g,'/')),
                        time:undefined
                    };

                    let nowTime = +new Date();

                    if (this.countDownConfig.endTime > nowTime) {
                        this.countDownId = countDownId;
                        this.formatCountDown();
                    }
                }
            } else {
                this.clearCountDown();
            }


        },

        formatCountDown(){
            let nowTime = +new Date();
            let diff = Math.floor((this.countDownConfig.endTime - nowTime)/1000);
            if(diff > 0) {

                // 计算单位
                this.countDownIdFormat = [
                    {
                        value: Math.floor(diff / 60 / 60 / 24),
                        format:'天'
                    },
                    {
                        value: Math.floor(diff / 60 / 60 % 24),
                        format:'时',
                        formatAdd:true
                    },
                    {
                        value: Math.floor(diff / 60 % 60),
                        format:'分',
                        formatAdd:true
                    },
                    {
                        value: Math.floor(diff % 60),
                        format:'秒',
                        formatAdd:true
                    }
                ];

                if (this.countDownId) {
                    this.countDownConfig.time = setTimeout(()=> this.formatCountDown(),1000);
                } else {
                    this.clearCountDown();
                }

            } else {
               return this.CountDownComplete();
            }
        },

        clearCountDown(){
            if (this.countDownId) {
                this.countDownId = undefined;
                clearTimeout(this.countDownConfig.time);
            }
        },

        CountDownComplete(){
            this.clearCountDown();
        }

    },

    created() {
        this.player = this.getSocket();
        this.player.on(PlayerMessage.streamEvent,(data)=>{
            if (data.event === 1) {
                this.player.playLiveVideo();
                this.clearCountDown();
            } else {
                this.createdCountDown(this.countDown);
            }
        });
    }

}
