import Throttle from '$utils/tool/throttle';

export default {

    methods:{
      changeWindowSize(){
          if (this.player && this.player.player) {
              let el = document.getElementById('player-container');
              this.player.player.setPlayerSize(
                  // 宽度，单位为像素
                  el.offsetWidth,

                  // 高度，单位为像素
                  el.offsetHeight
              );
          }
      }
    },

    created(){

        this.sizeChangeThrottle = new Throttle({
            handle:this.changeWindowSize,
            call: this,
            delay:300,
            first:false
        });

        this.changeWindowSize = this.sizeChangeThrottle.supper;

        // @ts-ignore
        window.observer_size['home'] =  (config)=> {
            return this.changeWindowSize(config);
        }
    },

    beforeUnmount(){
        // @ts-ignore
        delete window.observer_size['home'];
        this.sizeChangeThrottle && this.sizeChangeThrottle.destroy();
    }

}
