export default <LibMixins>{

    computed:{

        container(){
            return this.$refs.container;
        },

        image(){
            return this.$refs.image;
        },

        videoEl(){
            return this.$refs.video
        }

    }

}
