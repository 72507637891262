import imageStatus from '../const/status';

export default <LibMixins> {

    data:function () {
        return {
            // 枚举 类型
            imageStatus,
            // 当前图片加载的状态
            status: imageStatus.loading
        }
    },

    methods:{
        // 设置加载状态
        setStatus(status:imageStatus):boolean{
            if (this.status !== status) {
                this.status = status;

                if (status === imageStatus.success){

                    this.$emit('success');
                } else if (status === imageStatus.fail) {
                    this.$emit('fail');
                }

                return true;
            }
            return false;
        },
        // 加载成功后触发
        onLoadSuccess(): void {
            // 设置图片尺寸
            this.setImageSize(this.image);
            // 如果为视频触发
            if (this.video) this.image.disablePictureInPicture = true;
            // 设置图片尺寸成功后 设置 状态为 加载成功
            return this.$nextTick(()=> this.setStatus(imageStatus.success));
        },
        // 加载失败后触发
        onLoadFail(): void {

            //  设置 状态为 加载失败
            return this.$nextTick(()=> this.setStatus(imageStatus.fail));
        }

    }

};
