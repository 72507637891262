import {Module} from "vuex";

import userConfig from '$config/user';

import storage from '$utils/tool/storage';

export default <Module<any,any>>{

    state:{
        user:{},
        shield:{}
    },

    getters:{
        isLogin(state){
          // return !!(state.user && state.user.id);
            return true;
        }
    },

    mutations:{

        setUserOtherInfo(state,data){

            if (data && state[data.key]) {
                state[data.key] = data.value;
            }

        },

        initializationUser(state,user){
            let userData = user || userConfig.user;

            if (userData) {
                userConfig.user = userData;
                state.user = userData;
            }
        },

        // 设置缓存
        setUserInfo(state,user){

            let storageData = JSON.stringify(user);
            if (user && userConfig.stringify !== JSON.stringify(user)) {


                userConfig.stringify = storageData;

                userConfig.user = user;

                state.user = user;
                return storage.setItem(userConfig.storageKey,user,userConfig.storageTime);
            }

        },

        // 退出登录
        outLogin(state){

            userConfig.user = null;
            userConfig.stringify = null;
            state.user = {};
            return storage.removeItem(userConfig.storageKey);

        }

    },

    actions:{

        outLogin({ commit }){
            // 清除用户信息
            commit('outLogin');


        },



    }

}
