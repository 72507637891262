import {DateFormat} from "$utils/tool/date";
import {PlayerMessage, RoomChatType} from "@/pages/home/components/chat/components/const";

export default {

    data(){
        return {
            // 消息存储对象
            messageTopData:{},
            // 消息排序
            messageTopDataOrder:[],
            // 时间
            messageTopTime:{}
        }
    },

    methods:{
        // 添加消息
        pushTopMessage(type,item,messageType,push:boolean=true){
            console.log(this);
            if (!this.messageTopData[item.id]) {

                // 执行设置间隔时间
                let date;
                if (this.lastTopDate) {
                    date = this.lastTopDate;
                } else {
                    date = new Date();
                }

                let nowDate = DateFormat(new Date(),'YYYY-MM-DD');
                let useDate = DateFormat(item.dateline,'YYYY-MM-DD');

                // 执行判断是否为同一天
                let diff = Math.abs(Math.floor(+date/1000) - item.dateline);

                if (diff >= (60 * 3)) {
                    this.lastTopDate = new Date(Number(item.dateline+'000'));
                }

                let format = nowDate===useDate ? 'hh:mm':'YYYY-MM-DD hh:mm';

                let diffDay = parseInt(nowDate.substr(8)) - parseInt(useDate.substr(8));
                if (diffDay == 1) {
                    format = '昨天 hh:mm'
                } else if (diffDay==2) {
                    format = '前天 hh:mm'
                }

                let useDateItem =  {
                    message: DateFormat(this.lastTopDate,format),
                    id: item.dateline + '-' + item.id
                };

                if (this.messageTopTime[useDateItem.message] === undefined) {
                    this.messageTopTime[useDateItem.message] = [
                        {
                            type: RoomChatType[PlayerMessage.time],
                            item:useDateItem,
                            messageType: PlayerMessage.time
                        }
                    ];
                    this.messageTopDataOrder.unshift(useDateItem.message);
                    this._messageTopDataOrder[useDateItem.message] = [];
                }

                this.messageTopData[item.id] = useDateItem.message;
                this._messageTopDataOrder[useDateItem.message].push(item.id);
                this.messageTopTime[useDateItem.message].push({
                    type,
                    item,
                    messageType
                });

                if (push) {
                    this.sendMessageScroll();
                }

                return true;
            }

            return  false;

        },

        // 删除置顶消息
        deleteTopMessage(data){

            if (this.messageTopData[data.id]) {


                let target = this.messageTopData[data.id];

                delete this.messageTopData[data.id];

                let useData = this._messageTopDataOrder[target];
                let useTarget = this.messageTopTime[target];


                if (useData && useTarget) {

                    let index = useData.indexOf(data.id);

                    useData.splice(index,1);

                    useTarget.splice(index + 1,1);
                    if (useTarget.length <= 1) {
                        delete this.messageTopTime[target];

                        this.messageTopDataOrder.splice(
                            this.messageTopDataOrder.indexOf(target),
                            1
                        );
                    }

                }


            }
        },
    },

    mounted() {

        this._messageTopDataOrder = {};
        // 置顶消息
        this.messageTopCallback = function (data) {
            if (data.top === 1) {
                console.log(data);
                return this.triggerPushMessage(data.msg_type,data);
            } else {
                return this.deleteTopMessage(data);
            }
            // return this.deleteMessage(data);
        }.bind(this);
        // 监听message
        this.agoraRTMIM.on(PlayerMessage.top,this.messageTopCallback);

    },

    beforeUnmount() {
        this.agoraRTMIM.off(PlayerMessage.top,this.messageTopCallback);
        delete this.messageTopCallback;
    }

}
