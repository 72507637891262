export default {

    // 消息
    'message':{
        icon: require('../images/empty.png'),
        ratio:0.11,
        size:180
    }

}
