import {PlayerMessage} from "$utils/class/Player/const";

export default {

    data(){
        return {
            giftData:[],
            giftRanking:[]
        }
    },

    methods:{

        getGiftList(){
            this.player.getGiftRanking().then((data)=>{

                let giftList = (data.data || {}).gift_list || [];

                this.giftData = giftList.map((item,index)=>{
                    this.giftRankingObject[item.id] = index;
                    return {
                        id: item.id,
                        price: item.price,
                        label: item.name,
                        icon: item.logo
                    }
                });
                this.giftRanking = giftList;
            });
        },

        // 为某一个礼物增加赠送数量
        addGiftRanking(data){

            let id = data.donate_info.id;
            // 如果存在此礼物触发
            if (this.giftRankingObject[id] !== undefined) {

                let index = this.giftRankingObject[id];

                this.giftRanking[index].count += data.number;

                let replaceIndex = undefined;
                for (let i=index - 1;i>=0;i--) {

                    if (this.giftRanking[index].count <= this.giftRanking[i].count) {
                        break;
                    } else {
                        replaceIndex = i;
                    }

                }

                if (replaceIndex !== undefined) {
                    let item = this.giftRanking[index];
                    this.giftRanking.splice(index,1);
                    this.giftRanking.splice(replaceIndex,0,item);

                    this.giftRanking.map((item,index)=>{
                        this.giftRankingObject[item.id] = index;
                    });

                }

            }

        }

    },

    mounted() {
        this.giftRankingObject = {};
        this.player.on(PlayerMessage.gift, ({data})=> {
            return this.addGiftRanking(data.gift);
        })
    }

}
