export default {

    /*
    *   图片地址
    *   如果为视频默认截取视频第一张， 注意 针对 跨域等问题 可根据 videoMode 最终采用 video 加载
    * */
    src:{
        type: String,
        default:''
    },

    /* 设置baseURL */
    baseURL:{
        type: String,
        default:undefined
    },

    /* 如果为视频 设置为预设置第一帧图像 */
    poster:{
        type: String,
        default:undefined
    },

    /* 直接使用video */
    video:{
      type: Boolean,
      default: false
    },

    /* 如果为视频且存在跨域是否启用视频加载第一帧 */
    videoMode:{
        type:Boolean,
        default:false
    },

    /* 立刻设置路径 */
    initialization:{
      type: Boolean,
      default: false
    },

    /*
    *   加载时的背景颜色
    * */
    backgroundColor:{
        type: String,
        default:'#eee'
    },

    /* 加载成功是否允许背景色一直存在 */
    mergeSuccessColor:{
        type: [Boolean,String],
        default: false
    },

    /* 提供默认的的图标后 是否允许使用背景色 */
    mergeDefaultColor:{
      type: [Boolean,String],
      default: true
    },

    /*
    *  默认的的图标 或者 加载失败的图标(如果不提供加载失败)
    * */
    defaultIcon:{
        type: String
    },

    /* 提供加载失败的的图标后 是否允许使用背景色 */
    mergeFailColor:{
        type: [Boolean,String],
        default: true
    },

    /*
    *  加载失败的图标
    * */
    failIcon:{
        type: String
    },

    /*
     *  预设置 图片宽度和高度
     *      设置后如果外层不指定 宽度和高度 将 自动根据适配 为 预设置宽度 高度
     * */
    preset:{
        type: Boolean,
        default: false
    },

    /*
    *  预设置 宽度
    * */
    presetWidth:{
        type: Number
    },

    /*
     *  预设置 高度
    */
    presetHeight:{
        type:Number
    },

    /* 圆角 */
    radius:{
        type: String,
        value: '0'
    },

    /*
    *   加载中的样式
    * */
    loadClass:{
        type: String
    },

    /* 一直存在的 image 控制 class */
    imageClass:{
        type: String
    },

    /*
    *   img 初始化的 样式 此时不显示 请注意 设置 必要的 初始化样式
    * */
    entryClass:{
        type: String
    },

    /*
    *   img 加载成功后的 样式 执行动作 根据 duration 是否为 真 来绝对是否一直保留
    * */
    levelClass:{
        type: String
    },

    /* 是否显示视频控件 */
    controls:{
      type: Boolean,
      default: false
    },

    /* 是否开启事件穿透 */
    pointer:{
      type:Boolean,
      default: false
    },

    /* 图片模式
    *  scaleToFill	缩放模式，不保持纵横比缩放图片，使图片的宽高完全拉伸至填满 image 元素
    *  aspectFit	缩放模式，保持纵横比缩放图片，使图片的长边能完全显示出来。也就是说，可以完整地将图片显示出来。
    *  aspectFill	缩放模式，保持纵横比缩放图片，只保证图片的短边能完全显示出来。也就是说，图片通常只在水平或垂直方向是完整的，另一个方向将会发生截取。
    *  widthFix	缩放模式，宽度不变，高度自动变化，保持原图宽高比不变
    *  heightFix	缩放模式，高度不变，宽度自动变化，保持原图宽高比不变
    *  cover 缩放模式，保持纵横比缩放图片，可以完整地将图片显示出来。
    *  center 缩放模式 保证图片会居中显示出来
    * */
    mode:{
        type:String,
        default:'cover'
    },

    /* 默认图标 */
    defaultMode:{
        type: String
    },

    /* 失败的图标 */
    failMode:{
        type: String
    },

    /* 如果外层尺寸为 display:none 等不可见的形式 默认采用body 执行获取 */
    getSizeFailBody:{
        type: Boolean,
        default: true
    },

    /* 如果不希望通过body 获取 可以指定当前的显示状态 会根据显示的状态来设置获取 */
    display:{
        type: Boolean,
        default:undefined
    },

    /* 描边 */
    borderWidth:{
        type: Number,
        default:0
    },

    /* 描边颜色 */
    borderColor:{
        type:String,
        default:''
    }

}
