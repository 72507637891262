import { SubjectType } from '../const/index';
import { VerificationType } from '@/utils/verification/const/index';

export default {
    [SubjectType.question]:{
        where(item){
            if (item.multi_select) {
                return this.config.multiple;
            } else {
                return  this.config.radio
            }
        },
        config:{
            radio:{
                title:'单选题',
                change(parent,item,vm){
                    if (parent.value !== item.key) {
                        parent.value = item.key;
                        parent.valueObject = {
                            [item.key]:1
                        }
                    }
                },
                active:'bulletin-radio-active',
                rules:{
                    [VerificationType.length]:'请补全必填项'
                },
                format:function (value) {
                    if (value) {
                        return  [value];
                    } else {
                        return [];
                    }
                },
            },
            multiple:{
                title:'多选题',
                change(parent,item,vm){
                    if (parent.value === undefined) {
                        parent.value = [];
                        parent.valueObject = {};
                    }

                    if (parent.valueObject[item.key]) {
                        delete parent.valueObject[item.key];
                        parent.value.splice(parent.value.indexOf(item.key),1);
                    } else {
                        parent.value.push(item.key);
                        parent.valueObject[item.key] = 1;
                    }
                },
                format:function (value) {
                    if (value) {
                        return  value;
                    } else {
                        return [];
                    }
                },
                rules:{
                    [VerificationType.length]:'请补全必填项'
                }
            }
        }
    },
    [SubjectType.questionAnswer]:{
        title:'问答题',
        rules:{
            [VerificationType.empty]:'请补全必填项'
        }
    },
    [SubjectType.input]:{
        title:'问答题',
        content:false,
        rules:{
            [VerificationType.empty]:'请补全必填项'
        }
    },
    [SubjectType.textarea]:{
        title:'问答题',
        content:false,
        rules:{
            [VerificationType.empty]:'请补全必填项'
        }
    }
}
