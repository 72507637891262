import { componentType } from "../data/tab";
import request from "$utils/request";
export default {

    data(){
        return {
            goTopStatus:false,
            barrage:true,
            userInfo:{},
            tabMenu:[],
            initStartLoading:true,
            tabMenuOrder:{
                type:1
            }
        }
    },

    provide(){
        return {
            loginSuccess:()=> this.loginSuccess()
        }
    },

    computed:{
      tabMenus(){
          let tabData = this.tabData[ this.$store.state.config.mobile?'mobile':'pc'] || [];
          let resultTabData = [...tabData,...this.tabMenu];
          if (this.tabMenuOrder.type === 1) {
              let order = [...(this.tabMenuOrder.datas || [])];
              if (order.indexOf('expert_introduce') < 0) {
                  order.unshift('expert_introduce');
              }

              let menuObject = {};

              resultTabData.map((item)=>{
                  menuObject[item.id] = item;
              });

              let menus = [];

              order.map((item)=> {
                  if (menuObject[item]) {
                      menus.push(menuObject[item]);
                  }
              })

              return menus;
          } else {
              return resultTabData;
          }
      }
    },

    methods:{

        getMenuOrder(){
          this.$request({
              url:'mudu/menu/list',
              method:'GET',
              params:{
                  // @ts-ignore
                  id:window.channelId
              }
          }).then((data)=>{
              this.tabMenuOrder = data.data || {};
          })
        },

        setMenus(menus){

            let tabMenus = [];

            this.storageInfo(menus);
            menus.map((item)=>{
                if (componentType[item.type]) {

                    tabMenus.push({
                        id:item.menu_id,
                        component: componentType[item.type],
                        label: item.name,
                        item:item,
                        hide: item.hide || false,
                        slot: 'slot-'+item.menu_id,
                        loading: item.type === 1 ? true : undefined
                    })
                }
            });

            this.tabMenu = tabMenus.sort((item,beforeItem)=>{
                return item.item.order >= beforeItem.item.order ? 1 : -1;
            });

        },

        // 获取专家列表
        fetchExpertList(){
            this.$request({
                url:'mudu/expert/expert_list',
                params:{
                    // @ts-ignore
                    id:window.channelId
                }
            }).then((data)=>{
                if (data.count > 0) {
                    this.tabData['mobile'].push({
                        id: 'expert_introduce',
                        label:'专家介绍',
                        component:'expert-introduction',
                        slot:'expert-introduction',
                        item:{
                            data:data.data
                        },
                        hide:true
                    });
                }
            });
        },

        // 执行缓存
        storageInfo(menus){
            localStorage.setItem(this.storageMenusKey,JSON.stringify({
                menus,
                themes:this.Themes
            }));
        },

        // 加载缓存
        initStorage(){
            let storageData = localStorage.getItem(this.storageMenusKey);
            if (storageData) {
                try {
                    let resultStorageData = JSON.parse(storageData);
                    resultStorageData.menus && this.setMenus(resultStorageData.menus);
                    this.Themes = resultStorageData.themes;
                    this.changeFavicon(this.Themes.cover_img);
                    this.initStartLoading = false;
                } catch (e) {

                }
            }
        },

        loginSuccess(must:boolean,first:boolean=false){
            if (this.status === this.constStatus.loading && !must) return;
            this.setStatus(this.constStatus.loading);
            // 开始加载
            return this.initialization(first);
        },

        initialization(first:boolean=false){
            this.installPlayer(first);
        },

        scroll(config){
            let status = config.y.y > config.y.height;
            if (this.goTopStatus !== status) {
                this.goTopStatus = status;
            }
        }
    },

    created() {

        // @ts-ignore
        this.storageMenusKey = 'live-'+window.channelId;

        this.initStorage();

        this.getMenuOrder();

        return this.fetchExpertList();
    }

    // mounted() {
    //
    //     return;
    //     if (this.$store.getters.isLogin) {
    //         this.setStatus(this.constStatus.loading);
    //         this.$request({
    //             url:'index/index/user_info',
    //             data:{
    //                 email: user.user.email
    //             },
    //             mode: constName.mode.main,
    //             message: InstructionsMessageType.other,
    //             failMessage:true
    //         }).then((data)=>{
    //             if (data.isSuccess) {
    //                 if (data.data.login_time > user.user.now_time) {
    //                     this.setStatus(this.constStatus.none);
    //                     this.$store.dispatch('outLogin');
    //                     this.player && this.player.remove();
    //                     return popup.$toast("账号在其他地方登录");
    //                 } else {
    //                     return this.loginSuccess(true);
    //                 }
    //             } else {
    //                 this.setStatus(this.constStatus.none);
    //                 return this.$store.dispatch('outLogin');
    //             }
    //
    //         });
    //         // this.$request({
    //         //     url:''
    //         // }).then((data)=>{
    //
    //         // });
    //         // this.loginSuccess(true);
    //     }
    // }

}
