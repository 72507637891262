

interface FunctionUseFunction {
    (this:LibMixins, item:TriggerData, index:number): any
}

interface FunctionUseAsyncFunction {
    (this:LibMixins, item:TriggerData, index:number): Promise<FunctionUseFunction>
}

type FunctionUseParams = string | FunctionUseFunction | FunctionUseAsyncFunction;

export default class FunctionTrigger implements Trigger {

    constructor(private triggerFunction:FunctionUseParams){

    }

    trigger(item: TriggerData, index: number, target: LibMixins): void {

        if (typeof this.triggerFunction === 'string') {
            // @ts-ignore
            return target[this.triggerFunction](item,index);
        }

        // 执行
        let params:any = this.triggerTarget(this.triggerFunction,item,index,target);
        // 如果为异步触发
        if (params instanceof Promise) {
            params.then((useFunction:FunctionUseFunction)=> this.triggerTarget(useFunction,item,index,target)).catch((useFunction:FunctionUseFunction)=>  this.triggerTarget(useFunction,item,index,target))
        }
    }

    triggerTarget(trigger:FunctionUseFunction, item: TriggerData, index: number, target: LibMixins):any{
        if (typeof trigger === 'function') {
            return trigger.call(target,item,index);
        }
    }

}
