import {nextTick} from "vue";

export default {

    data(){
        return {
            questionnaire:false,
            questionnaireDraw:false
        }
    },

    methods:{
        toggleQuestionnaire(){
            this.questionnaire= !this.questionnaire;
            if (!this.questionnaireDraw) {
                this.questionnaireDraw = true;
            }
        }
    }

}
