import instructions from './create';

import config from '../../config';

import InConfig from './config';

import user from '$config/user';

import {
    InstructionsWhere,
    InstructionsTypes,
    LibRequestStatus
} from '../../const/request';

import verification from '$utils/verification/index';

import { Mode } from '../../const/index';
import popup from "$utils/tool/popup";

export default instructions.pushConfig({

    // 初始化触发
    default:{
      where: InstructionsWhere.skip,
      trigger:function (data){

          if (data.requestData.mode && data.requestData.mode !== Mode.default && config[data.requestData.mode]) {

              let targetConfig:Record<string, any> = config[data.requestData.mode || Mode.default];

              // 执行配置合并
              data.requestData = {...targetConfig,...data.requestData};

              // 设置 url
              if (data.requestData.url.indexOf('http') !== 0) {
                  data.requestData.url = data.requestData.baseURL + data.requestData.url;
                  delete data.requestData.baseURL;
              }
          }


      }
    },

    // 验证
    verification:{
        trigger:function (data) {

            let { requestData,exit } = data;

            if (requestData.data) {

                let resultData = verification.verification(requestData.data as LibDataArray,true);

                // 如果验证通过
                if (resultData.verification) {
                    // 设置请求 data
                    requestData.data =  typeof requestData.test === 'function' ? requestData.test(resultData.value) || resultData.value : resultData.value

                } else {
                    return exit('test fail '+resultData.value);
                }

            }

        },
        zIndex:10
    },

    // 设置token
    token:function ({requestData}){

        if (requestData.data === undefined) requestData.data = {};
        let tokenKey = InConfig.getTargetConfig(requestData.mode,'user').token;
        if (user.user && user.user[tokenKey]) {
            requestData.data[tokenKey] =  user.user[tokenKey];
        }

    },

    // 设置分页
    page:function ({requestData}){
       if (requestData.data === undefined) requestData.data = {};
       let pagesOption = InConfig.getTargetConfig(requestData.mode,'pageOption');
       requestData.data[pagesOption.page] = requestData.page.page;
       requestData.data[pagesOption.pageSize] = requestData.page.pageSize;
    },

    // 加载提示
    loading:{
      triggerType:InstructionsTypes.all,
      trigger:function ({requestData,status}){

          // 首次触发
          if (status === LibRequestStatus.loading) {

              if (typeof requestData.loading === 'string'){
                  // @ts-ignore
                  requestData.loading = {title:requestData.loading}
              }

              let resultLoading:InstructionsLoading = (requestData.loading as InstructionsLoading);

              if (resultLoading.delay === undefined || resultLoading.delay > 0){
                  resultLoading._time = setTimeout( ()=>{
                      delete resultLoading._time
                      return this.triggerOpenLoading(resultLoading.title,resultLoading.mask);
                  },resultLoading.delay || 1000);
              } else {
                  return this.triggerOpenLoading(resultLoading.title,resultLoading.mask);
              }

          } else {
              let resultLoading:InstructionsLoading = (requestData.loading as InstructionsLoading);
              if (resultLoading._time) return clearTimeout(resultLoading._time);
              else return  popup.hideLoading();
          }

      },
      triggerOpenLoading(title:string,mask:boolean = true){
          return  popup.$loading({
              title,
              mask
          });
      },
      zIndex:1
    },

    data:{
        where: InstructionsWhere.has,
        trigger:function (data) {

            let formData = [];
            if (data.requestData.data) {
                for (let key in data.requestData.data) {
                    if (data.requestData.data.hasOwnProperty(key)) {
                        formData.push(key+'='+data.requestData.data[key]);
                    }
                }
            }

            // @ts-ignore
            data.requestData.data = formData.join('&');


        },
        zIndex:-9
    },

    file:{
      where: InstructionsWhere.has,
      trigger:function (data) {

          let formData = new FormData();

          if (data.requestData.data) {
              for (let key in data.requestData.data) {
                  if (data.requestData.data.hasOwnProperty(key)) {
                      formData.append(key,data.requestData.data[key]);
                  }
              }
          }

          data.requestData.data = formData;

      },
      zIndex:-9
    },

    // 结束时触发
    end:{
        where:InstructionsWhere.skip,
        trigger:function (data){
            /* 配置最终执行代理 */
            data.success = function (useData:LibRequestResponseData) {
                data.responseData = useData;

                if (typeof useData.data === 'string') {
                    try {
                        useData.data = JSON.parse(useData.data);
                    } catch (e) {

                    }
                }

                data.status = useData.status === 200 && typeof useData.data ==='object' ? LibRequestStatus.success : LibRequestStatus.fail;
                return instructions.trigger(data,InstructionsTypes.end);
            }
            data.fail = function (useData:any) {
                data.responseData = useData;
                data.status = LibRequestStatus.fail;
                return instructions.trigger(data,InstructionsTypes.end);
            }
        },
        zIndex:-10
    }

});
