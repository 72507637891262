import {QueueItem} from "$utils/tool/queue";
import {nextTick} from "vue";

export default {

    methods:{

        pushQueue(item){

            // item.id = +new Date() +'-'+Math.floor(Math.random() * 10000);


            // 查找所有管道是否出现过此id
            let has = false;
            this.column.map((parent,column)=>{
                if (parent && parent.item.id === item.id) {
                    has =true;
                    item.sendNumber =(parent.item.sendNumber || 1) + 1;
                    parent.item = item;
                    if ( this._column_last[column]) {
                        this._column_last[column].createdTime =  +new Date();
                    }
                }
            });

            if (!has) {
                // 追加
                this.config.queue.push(new QueueItem(item));
                // 执行队列
                if (!this.queueStatus) {
                    return this.triggerQueue();
                }
            }
        },

        triggerQueue(){



            if (this.queueStatus) return ;

            clearTimeout(this.queueWaitTime);

            let createdTime = +new Date();

            // 查找空管道
            let column = this.searchEmptyColumn(createdTime);

            if (typeof column === 'object') {
                // 当前处于排队状态
                this.queueStatus = true;
                // 设置等待
                this.queueWaitTime = setTimeout(()=>{
                    this.queueStatus = false;
                    return this.triggerQueue();
                },column.time);
            }
            // 设置查找到的空管道
            else{

                // 如果排队模式还为真设置为false
                if (this.queueStatus) this.queueStatus =false;

                // 获取当前可被执行对象
                let item = this.config.queue.shift();

                // 如果存在触发
                if (item) {
                    // 取消排队状态
                    this.queueStatus = false;
                    // 创建配置文件
                    let config:Record<string, any> = {
                        item:item.data,
                        createdTime: createdTime,
                        duration: this.config.duration,
                        width:0,
                        endTime:this.config.duration
                    }


                    /* 设置管道 */
                    this._column_last[column] = config;
                    this.column[column] = config;
                    /* 设置配置文件 */
                    // 继续执行队列
                    return this.triggerQueue();

                }
            }

        },

        // getString(){
        //   let value = Math.floor(Math.random() * 20);
        //   let str= '';
        //   for (let i=0;i<value;i++) {
        //       str+= this.getRandomChineseWord();
        //   }
        //   return str;
        // },
        //
        // getRandomChineseWord() {
        //     let _rsl = "";
        //     let _randomUniCode = Math.floor(Math.random() * (40870 - 19968) + 19968).toString(16);
        //     eval("_rsl=" + '"\\u' + _randomUniCode + '"');
        //     return _rsl;
        // }


    },


    created() {
        // 是否处于排队状态
        this.queueStatus = false;
        // 最后一个
        this._column_last = [];
        // 测试使用
        // setInterval(()=>{
        //     this.pushQueue({
        //        id:123,
        //        message:'123'
        //     });
        // },500);
    },

    beforeUnmount() {
        clearTimeout(this.queueWaitTime);
    }

}
