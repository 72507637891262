
import props from '../props';

import mixins from '../mixins';

export default {

  name:'scroll-view',

  mixins,

  props

}


