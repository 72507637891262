<template>
  <i class="iconfont icon-default"
    :class="['icon-'+type]"
  ></i>
</template>

<script>
import props from '../props';
export default {
  name: "icon",
  props
}
</script>

<style scoped lang="scss" src="../style.scss"></style>