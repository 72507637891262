import {ContainerScrollStyle, ContainerStyle, ContainerStyleFlex} from "$components/scroll-view/types/style";

import unit from "$utils/unit/unit";

export default <LibMixins>{

    computed:{
        // 容器
        containerStyle():ContainerStyle | ContainerStyleFlex{
            if (this.flex) {
                return  {
                    flexGrow:1,
                    flexShrink:1
                }
            } else {
                return {
                    width:this.width?this.width.toString():'100%',
                    height: this.height?this.height.toString():'100%'
                }
            }

        },
        // 滚动容器
        scrollContainer():any{
            return {
                overflowY: this.scrollY ? 'auto':'hidden',
                overflowX: this.scrollX ? 'auto':'hidden',
                width: this.securitySize ? 'auto': '',
                overscrollBehavior: this.behavior,
                marginRight: unit.unitPx(this.securitySize) * -1  + 'px',
                paddingRight: unit.unitPx(this.securitySize)  + 'px',
            }
        }
    }

}
