<template>
  <div class="ranking-screen container-screen">
    <div class="container-screen ranking-content">
      <v-tab
        :data="tabData"
        class="ranking-tab"
        v-if="!loading"
        @change="changeTab"
      >
        <template
            v-for="(item,index) in tabData"
            v-slot:[item.slot]
            :key="'ranking-'+index"
        >
          <div class="ranking-padding">
            <flat-list
                @fetch="fetch($event,item)"
                :paging="false"
                :page-size="item.pageSize"
                :ref="item.slot"
            >
              <template v-slot:empty>
                <img class="ranking-empty-icon" src="../images/empty.png" />
                <div class="ranking-empty-text">暂无榜单内容</div>
              </template>
              <template v-slot:layout="{data}">
                <div class="ranking-item-group rowACenter">
                  <div
                      v-for="(item,index) in data"
                      :key="index"
                      class="ranking-item center"
                  >
                    <v-image :src="item.avatar" radius="50%" class="ranking-item-avatar"
                             :style="{
                                'border-color': (levelData[index] || levelData.default).color
                             }"
                    >
                      <img v-if="(levelData[index] || levelData.default).icon" :src="(levelData[index] || levelData.default).icon" class="ranking-item-avatar-level" />
                    </v-image>
                    <div class="ranking-item-username">{{item.name}}</div>
                    <div class="center">
                      <div class="ranking-item-number center"
                           :style="{
                      'background-color': (levelData[index] || levelData.default).color
                    }"
                      >{{item.count}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </flat-list>
          </div>

        </template>

      </v-tab>
      <div class="screen center" v-else>
        <v-loading size="large"></v-loading>
      </div>

    </div>
  </div>
</template>

<script>
import vTab from '@/components/tab';
import tabObject from '../data/tab';
import flatList from '@/components/flat-list';
import VImage from "$components/v-image/lib/main";
import levelData from '../data/level';
import props from '../props';
import {FlatListReload} from "$components/flat-list/const";
import VLoading from "$components/loading/src/main";
export default {
  name: "ranking",
  data(){
    return {
      tabData:[],
      levelData
    }
  },
  props,
  inject:['getSocket'],
  methods:{
    fetch(obj,item){
      return this[item.fetch](obj);
    },
    fetchChat(obj){
      return this.socket.getChatRanking().then((data)=>{
        return obj.success(data.rank_list || []);
      }).catch(obj.fail);
    },
    fetchContribution(obj){
      return this.socket.getContribution().then((data)=>{
        return obj.success(data.rank_list || []);
      }).catch(obj.fail);
    },
    changeTab(item){
      this.$refs[item.item.slot] && this.$refs[item.item.slot].reload(FlatListReload.reload);
    }
  },
  created() {
    this.socket = this.getSocket();
    let tabData = [];
    this.item.ranks.map((item,index)=>{
      if (tabObject[item.type]) {
        tabData.push({
          label: item.title,
          pageSize: item.limit,
          slot:'tab-'+index,
          fetch: tabObject[item.type]
        });
      }
    });
    this.tabData = tabData;
  },
  components:{
    VLoading,
    VImage,
    vTab,
    flatList
  }
}
</script>

<style scoped src="../style.scss" lang="scss"></style>
