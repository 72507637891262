export default {

    rankData:{
        type:Array,
        default:function () {
            return [];
        }
    }

}
