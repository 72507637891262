export default {

    // 获取比例
    getRatio(size:Size):number{
        return size.width / size.height;
    },

    // 根据比例计算高度
    getRatioHeight(diff:number,ratio:number):number {
        return diff / ratio;
    },

    // 根据比例计算宽度
    getRatioWidth(diff:number,ratio:number):number{
        return diff * ratio;
    },

    // 获取左侧间距
    getLeft(width:number,container: Size):number {
        return (container.width - width - (container.margin || 0)) / 2;
    },

    // 获取上侧间距
    getTop(height:number,container: Size):number {
        return (container.height - height - (container.margin || 0)) / 2;
    },

}
