export default <LibMixins>{


    methods:{
        // 播放视频
        play():Promise<void> | void{
            if (this.video) {
               return this.image.play();
            }
        },

        // 暂停
        pause():void{
            if (this.video) {
                return this.image.pause();
            }
        },

        // 获取自定义
        getVideoElement():Element | void{
            if (this.video) {
                return this.image;
            }
        }
    }



}
