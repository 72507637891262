import {PlayerMessage} from "$utils/class/Player/const";

export default {

    inject:['getSocket','toggleGiftModal'],

    data(){
        return {
            giftNumber:0,
            giftIndex:0,
            giftModal:{},
            sendLoadingStatus:false
        }
    },
    methods:{
        routerGift(index){
            if(this.giftIndex !== index) {
                this.giftIndex = index;
            }
        },
        handleHoverChange(index,visible){
            this.giftModal[index] = visible;
        },
        // 赠送礼物
        sendGift(item){

            if (item == undefined) item = this.giftData[this.giftIndex];

            if (this.sendLoadingStatus || !item) return;
            this.sendLoadingStatus = true;

            let userInfo = this.agoraRTMIM.getUserInfo();

            this.toggleGiftModal();



            this.agoraRTMIM.sendGift({
                num: 1,
                gift_id: item.id
                // gift:{
                //     ...item,
                //     src: location.origin+'/'+item.icon
                // },
                // number: this.giftNumber,
                // introduce:userInfo.nick + '送出'+this.giftNumber+'个'+item.label
            },PlayerMessage.gift).then(()=>{
                this.sendSuccess(item);
            }).catch(()=>{
                this.sendSuccess(item);
            });

        },

        sendSuccess(){
            this.sendLoadingStatus = false;
            this.giftNumber = 1;
        }
    },

    mounted() {
        // 聊天对象
        this.agoraRTMIM = this.getSocket();
    },

}
