import {PlayerMessage} from "$utils/class/Player/const";

export default {

    data(){
        return {
            queueTime:0
        }
    },

    inject:['getSocket'],

    methods:{

        // 增加可播放动画
        pushGift(item) {

            if (item && item.gift && item.gift.donate_info && item.gift.donate_info.is_highlight) {
                return this.pushQueue({
                    ...item.gift.donate_info,
                    sendNumber:1
                });
            }
        }

    },

    mounted() {
        // 聊天对象
        this.agoraRTMIM = this.getSocket();

        this.agoraRTMIM.on(PlayerMessage.gift,({data})=>{
            return this.pushGift(data);
        })
    },

    beforeUnmount() {
        this.agoraRTMIM.off(PlayerMessage.gift);
    }


}
