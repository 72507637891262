import { Queue,QueueItem } from '$utils/tool/queue';
import animateData,{AnimateType} from '../data/animate';
export default <LibMixins>{

    data(){
        return {
            // 执行队列数组
            useQueueObject: {},
            queueTime: 3000,
            triggerStatus: false,
            useQueueItem: undefined
        }
    },

    methods:{
        pushQueue(data){

            if (!data.gift || !data.gift.donate_info || !AnimateType[data.gift.donate_info.action_type]) return ;

            // @ts-ignore
            data._id = new Date() * 1 + Math.floor(Math.random() * 1000);

            let unique = this.createdQueueId(data);

            data._unique = unique;

            let defaultAnimate = '';

            data.gift.animate = AnimateType[data.gift.donate_info.action_type];
            let animate = data.gift.animate;

            if (!animateData[animate]) {
                animate = defaultAnimate;
            }
            data._animate = {
                name:animate,
                config: animateData[animate]
            }

            if (this.queue[unique] === undefined) {
                this.queue[unique] = {
                    queue: new Queue(),
                    triggerStatus:false,
                    triggerTime:null,
                    unique:unique
                }
            }

            this.queue[unique].queue.push(new QueueItem(data));

            return this.triggerQueue(this.queue[unique]);
        },

        // 创建唯一id
        createdQueueId(data){
            return 'other-user';
        },

        // 执行队列
        triggerQueue(queue){

            if (!queue.triggerStatus) {

                clearTimeout(queue.triggerTime);

                queue.triggerStatus = true;

                return this.triggerQueueItem(queue);
            }

        },

        // 执行队列到具体的item
        triggerQueueItem(queue){
            if (!queue) return;
            // 获取第一个
            let item = queue.queue.shift();
            if (item){
                this.useQueueObject[queue.unique] = item.data;
                this.useQueueItem = item.data;
                this.triggerChangeQueue && this.triggerChangeQueue(this.useQueueObject[queue.unique]);

                this.triggerTime = setTimeout(()=> this.triggerStopQueue(queue.unique),item.data._animate.config.duration);
            } else {
                queue.triggerStatus = false;
                this.useQueueObject[queue.unique] = undefined;
                this.useQueueItem = undefined;
                clearTimeout(this.triggerTime);
            }

            // if (queue.triggerStatus && this.queueTime) {
            //     // 获取下一个
            //     this.triggerTime = setTimeout(()=> {
            //         this.triggerQueueItem(queue);
            //     },this.queueTime);
            // }
        },

        // 执行
        triggerStopQueue(unique){

            if (this.queue[unique]) {
                return this.triggerQueueItem(this.queue[unique]);
            }


        }

    },

    created() {
        // 创建执行队列
        this.queue = {};
        // 创建队列
        this.maxQueue = new Queue();
    },

    beforeUnmount() {
        clearTimeout(this.triggerTime);
    }

}
