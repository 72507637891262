export default {

    userInfo:{
        type:Object,
        default:function () {
            return {};
        }
    },

}
