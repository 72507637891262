import config from './config';

import type from '$utils/tool/type';

import popup from '$utils/tool/popup';

import { VerificationType } from './const';

export default {

    trigger:{

        [VerificationType.empty]: function(value){
            if (value === undefined || value===null || value !== value){
                return false;
            } else if (typeof value === 'string') {

                value = config.trim(value);

                return value !== '';

            } else {
                return true;
            }
        },

        // 手机号验证
        [VerificationType.mobile]:function(value:any){
            if (!value || typeof value === 'object') return false;
            else value = String(value);
            return /^1\d{10}$/.test(value);
        },

        // 邮箱验证
        [VerificationType.email]:function(value:any){
            if (!value || typeof value === 'object') return false;
            else value = String(value);
            return /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/.test(value);
        },

        // 是否相等
        [VerificationType.eq]:function(value:any,item:LibData){
            if (item.rulesOption && item.rulesOption.data) {
                return  item.rulesOption.data.value === value;
            }
            return false;
        },

        // 长度
        [VerificationType.length]:function(value:any,item:LibData){
            // @ts-ignore
            let min = item.rulesOption && item.rulesOption.min || 1;
            // @ts-ignore
            let max = item.rulesOption && item.rulesOption.max || 9999999999999999999999;
            let length = 0;

            if (!value) {
                return false
            } else if (value instanceof Array || value.length !== undefined) {
                length = value.length;
            } else if (typeof value === 'number') {
                length = value;
            } else {
                return false;
            }

            return length >= min && length <= max;
        },

        // 密码
        [VerificationType.password]:function(value){
            if (value && typeof  value === 'string') {
                return /[^\u4E00-\u9FA5]{6,16}/.test(value);
            }
            return false;
        },

        // 身份证号
        [VerificationType.id]:function(value){
            if (!value || typeof value === 'object') return false;
            else value = String(value);
            return /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/.test(value);
        }


    } as VerificationTrigger,

    // 校验item
    verificationItem: function (data: LibData, toast: boolean | string): VerificationResult {

        if (data.rules !== undefined) {

            let resultRules: Record<VerificationType, string>;
            if (typeof data.rules === 'string') {
                // @ts-ignore
                resultRules = {[VerificationType.empty]: data.rules}
            } else {
                resultRules = data.rules;
            }
            for (let key in resultRules) {

                // @ts-ignore
                if (resultRules.hasOwnProperty(key) && this.trigger[key] && !this.trigger[key](data.value, data)) {

                    // @ts-ignore
                    let value = resultRules[key] || data.placeholder || data.label;

                    // 如果不通过,是否自动提示
                    if (toast) {
                        popup.$toast(value);
                        // let rulesToast = typeof toast === 'boolean' ? 'info' : toast;
                        // if (toastModal[rulesToast]) {
                        //     toastModal[rulesToast](value);
                        // }
                    }

                    return {
                        verification: false,
                        value,
                        type: key as unknown as VerificationType
                    }
                }
            }

            return {
                verification: true,
                value: data.value
            }


        }

        return {
            verification: true,
            value: data.value
        }

    },

    // 校验
    verification(data:LibData | LibDataArray,toast:boolean|string):VerificationResult{

        let resultData:LibDataArray;
        if (type.isObject(data)) resultData = [data as LibData];
        else resultData = data as LibDataArray;

        let result:Record<string, any> = {};

        for (let i=0,count=resultData.length;i<count;i++) {

            let useResult:VerificationResult = this.verificationItem(resultData[i],toast);

            if (!useResult.verification) {
                return useResult;
            } else {

                let useKey = resultData[i].exportKey || resultData[i].key;

                if (useKey) {
                    result[useKey] = useResult.value;
                }

            }

        }

        return {
            verification:true,
            value:result
        }


    },

    // 校验返回 Promise
    verificationPromise(data:LibData | LibDataArray,toast:boolean|string):Promise<Record<string, any>>{

        return  new Promise<Record<string, any>>( (resolve,reject)=>{
            let result:VerificationResult = this.verification(data,toast);
            return result.verification ? resolve(result.value) : reject(result.value);
        });

    },

}

export {
    VerificationType
}
