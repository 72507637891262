import {
    createApp
} from 'vue'
import App from './App.vue'
import store from './store'
import 'ant-design-vue/dist/antd.css';
import request from "$utils/request";


const app = createApp(App).use(store).use(request).mount('#useApp')

