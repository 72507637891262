enum Status {

    loading='loading',

    success='success',

    fail='fail',

    none='none',

    default='default'

}

export default Status;