enum Mode {
    default,
    main
}

export default {

    mode:Mode

}

export {Mode};