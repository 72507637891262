
const requestContext = require.context('../images/expression',true,/\.gif$/);

let expressionData = {};

requestContext.keys().map((item)=>{
    // 获取模块
    let modal = requestContext(item);

    let key = (((item.split('/') || []).pop() || '').split('.') || [])[0] || item;

    let id = '[em_new_gif_'+key+']';
    expressionData[id] = {
        icon: modal,
        id
    };
});
export default expressionData;
