<template>
  <div class="chat-time">{{item.message}}</div>
</template>

<script>
import props from '../props';
export default {
  name: "chat-time",
  props
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
