import image from '../api/image';

import unit from '../api/unit';

import globalUnit from '$utils/unit/unit';

import containerMode from '../const/container';

export default <LibMixins> {

    computed:{

        /* 为图片设置的样式表 */
        imageStyle(){
            if (this.useImageSize && this.useContainerSize) {
                return unit.unit(image[this.mode]({
                    image: this.useImageSize,
                    container: this.useContainerSize,

                }));
            }
        },

        /* 为图标设置的样式 */
        iconStyle(){
            if (this.useIconSize && this.useContainerSize) {
                return unit.unit(image[this.mode]({
                    image: this.useIconSize,
                    container: this.useContainerSize
                }));
            }
        },

        /* 容器样式 */
        containerStyle(){
            if (this.imageStyle && containerMode[this.mode]) {
                let size:object = {};
                containerMode[this.mode].map((item:string)=>{
                    // @ts-ignore
                    size[item] = this.imageStyle[item];
                });
                return size;
            }
        },

        /* 提供给image的状态class */
        imageClassStyle(){
            switch (this.status) {
                case this.imageStatus.loading: return ['v-image-start',this.loadClass,this.entryClass,this.imageClass];
                case this.imageStatus.success: return [this.levelClass,this.imageClass];
                default: return undefined;
            }
        },

        /* 图片外部容器 */
        containerImageStyle(){

            let style:any = {};

            if (this.radius) {
                style.borderRadius = this.radius;
            }

            if (
                this.status === this.imageStatus.success
                &&
                this.mergeSuccessColor
            ) {
                style.backgroundColor = this.getBackgroundColor(this.mergeSuccessColor);
            } else if (!this.icon && this.containerIconStyle) {
                style.backgroundColor = this.containerIconStyle.backgroundColor;
            }

            if (this.borderWidth) {
                style.border = globalUnit.unitRem(this.borderWidth)+' solid '+this.borderColor
            }

            return style;

        },

        /* 图标容器颜色 */
        containerIconStyle(){
            if (
                this.status === this.imageStatus.loading
                &&
                (!this.defaultIcon || this.defaultIcon && this.mergeDefaultColor)
                ||
                this.status === this.imageStatus.fail
                &&
                (!this.failIcon && !this.defaultIcon || (this.failIcon || this.defaultIcon) && this.mergeFailColor)
            ) {

                return {
                    backgroundColor: this.getBackgroundColor(this.status === this.imageStatus.loading ?
                        this.mergeDefaultColor
                        :
                        this.mergeFailColor
                    )
                }

            }
        }


    },

    methods:{
        getBackgroundColor(color:boolean | string) : string{
            return typeof color === 'boolean' ? this.backgroundColor : color;
        }
    },

    created(){



    }

};
