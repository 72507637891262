export default {

    provide() {
        return {
            // 获取聊天对象
            getSocket:()=> this.player || {},
        }
    },

}
