<template>
<!-- initStartLoading && -->
<!--  <div class="home-loading center" v-if="loading">-->
<!--    <v-loading size="large"></v-loading>-->
<!--  </div>-->
  <login v-if="!$store.getters.isLogin"></login>
  <!-- 容器 -->
  <scroll-view
    v-else-if="draw"
    class="screen"
    @scroll="scroll"
    ref="scroll"
    :monitor="!$store.state.config.mobile"
    :minHeight="$store.state.config.mobile?'100%':'auto'"
    :scroll-x="!$store.state.config.mobile"
    :scroll-y="!$store.state.config.mobile"
  >
    <section class="container-screen"
             :style="{backgroundImage:Themes.banner?'url('+Themes.banner+')':''}">

      <queue-gift></queue-gift>

      <section class="container">

        <!-- 顶部 style="display: none"-->
        <section class="header row between"  >

          <article class="flex header-live overflow">
            <live :barrage="barrage" :countDown="countDown" :userInfo="userInfo" :background="Themes.live_img" :liveStatus="liveResultStatus" :loading="loading"></live>
            <div  class="header-info rowACenter" :class="{
               'header-info-mobile': $store.state.config.mobile
            }"
              v-if="!$store.state.config.mobile"
            >
              <v-image class="header-info-avatar overflow"
                background-color="#fff"
                :src="Themes.cover_img"
              >

              </v-image>
              <div class="flex-1 header-content overflow">
                <div class="line-1 header-title">{{Themes.name}}</div>
                <footer class="rowACenter header-footer">
                  <div class="rowACenter header-control-item">
                    <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3246" width="200" height="200"><path d="M512 209.403241c-201.731514 0-374.009206 125.476783-443.808922 302.596759 69.798692 177.119977 242.077408 302.596759 443.808922 302.596759 201.933105 0 374.010229-125.476783 443.808922-302.596759C886.009206 334.880023 713.933105 209.403241 512 209.403241zM512 713.731514c-111.355157 0-201.731514-90.375334-201.731514-201.731514s90.375334-201.731514 201.731514-201.731514 201.731514 90.375334 201.731514 201.731514S623.355157 713.731514 512 713.731514zM512 390.961296c-66.772776 0-121.038704 54.265928-121.038704 121.038704s54.265928 121.038704 121.038704 121.038704 121.038704-54.265928 121.038704-121.038704S578.772776 390.961296 512 390.961296z" p-id="3247" fill="#448aff"></path></svg>
                    <span>观众：{{live_num||0}}</span>
                  </div>
                </footer>
              </div>
              <article class="rowACenter"
                       :class="[!initLoading?'control-animate-show':'control-animate-hide']"
              >
                <template
                    v-for="(item,index) in controlData"
                    :key="'aside-'+index"
                >
                  <a-popover placement="top" v-if="item.is" trigger="click">
                    <template v-slot:content>
                      <component :is="item.is" :giftData="giftData" :rankData="giftRanking"></component>
                    </template>
                    <aside
                        class="header-item cursor-pointer center"
                    >
                      <v-image :src="item.icon" class="aside-image screen"></v-image>
<!--                      <aside-->
<!--                          class="aside-item center relative"-->
<!--                      >-->
<!--                        <div class="absolute aside-screen">-->
<!--                          <v-image :src="item.icon" class="aside-image screen"></v-image>-->
<!--                        </div>-->
<!--                        <div class="aside-content" :style="{backgroundColor:item.color}"></div>-->
<!--                      </aside>-->
                      <div class="aside-item-title">{{item.label}}</div>
                    </aside>
                  </a-popover>
                  <aside
                      v-else
                      class="header-item cursor-pointer center"
                      @click="trigger(item,index)"
                  >
                    <v-image :src="item.icon" class="aside-image screen"></v-image>
<!--                    <aside-->
<!--                        class="aside-item center relative"-->
<!--                    >-->
<!--                      <div class="absolute aside-screen">-->
<!--                        <v-image :src="item.icon" class="aside-image screen"></v-image>-->
<!--                      </div>-->
<!--&lt;!&ndash;                      <div class="aside-content" :style="{backgroundColor:item.color}"></div>&ndash;&gt;-->
<!--                    </aside>-->
                    <div class="aside-item-title">{{item.label}}</div>
                  </aside>
                </template>


              </article>
            </div>
<!--            <footer v-if="!$store.state.config.mobile" class="footer-control rowACenter">-->
<!--              <a-popover placement="bottom">-->
<!--                <template v-slot:content>-->
<!--                  <mobile></mobile>-->
<!--                </template>-->
<!--                <div class="rowACenter">-->
<!--                  <svg class="footer-control-phone" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2158" width="200" height="200"><path d="M544 910.336h-64a32 32 0 0 1 0-64h64a32 32 0 0 1 0 64zM560 192h-96a32 32 0 0 1 0-64h96a32 32 0 0 1 0 64zM816 800h-608a32 32 0 0 1 0-64h608a32 32 0 0 1 0 64z" p-id="2159" fill="#448aff"></path><path d="M784 1024h-544c-35.264 0-64-28.672-64-64V64c0-35.264 28.736-64 64-64h544c35.328 0 64 28.736 64 64v896a64 64 0 0 1-64 64z m0-64v32-32zM240 64v896h544V64h-544z" p-id="2160" fill="#448aff"></path></svg>-->
<!--                  <span>Mobile phone watch</span>-->
<!--                </div>-->
<!--              </a-popover>-->
<!--              <div class="flex-1"></div>-->
<!--              <span class="home-barrage">Barrage</span>-->
<!--              <a-switch class="home-switch" v-model:checked="barrage" checked-children="open" un-checked-children="close" />-->

<!--            </footer>-->
            <div v-if="$store.state.config.mobile" class="background-fixed"></div>
          </article>

          <aside v-if="!$store.state.config.mobile" class="header-chat flex overflow">
            <header class="header-chat-header rowACenter">
              <div class="flex-1">{{ questionnaire?'Questionnaire investigation':'聊天互动' }}</div>
              <svg t="1627959408031" class="header-chat-close cursor-pointer" @click="toggleQuestionnaire" v-show="questionnaire" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2050" width="200" height="200"><path d="M571.01312 523.776l311.3472-311.35232c15.7184-15.71328 15.7184-41.6256 0-57.344l-1.69472-1.69984c-15.7184-15.71328-41.6256-15.71328-57.34912 0l-311.3472 311.77728-311.35232-311.77728c-15.7184-15.71328-41.63072-15.71328-57.344 0l-1.69984 1.69984a40.0128 40.0128 0 0 0 0 57.344L452.92544 523.776l-311.35232 311.35744c-15.71328 15.71328-15.71328 41.63072 0 57.33888l1.69984 1.69984c15.71328 15.7184 41.6256 15.7184 57.344 0l311.35232-311.35232 311.3472 311.35232c15.72352 15.7184 41.63072 15.7184 57.34912 0l1.69472-1.69984c15.7184-15.70816 15.7184-41.6256 0-57.33888l-311.3472-311.35744z" p-id="2051" fill="#333333"></path></svg>
            </header>
            <div class="flex-1 relative">
              <div class="absolute absolute-screen">
                <questionnaire v-if="questionnaireDraw" v-show="questionnaire" :url="item.url"></questionnaire>
                <chat ref="chat" :userInfo="userInfo" :loading="loading" v-show="!questionnaire"></chat>
              </div>
            </div>
          </aside>

        </section>

        <!-- tab -->
        <section class="home-tab">
          <tab
            :data="tabMenus"
            :autoHeight="!$store.state.config.mobile"
            ref="tab"
            :key="'tab-'+tabMenus.length"
            @change="changeTrigger"
            :lazy="true"
          >
            <template
              v-for="(item,index) in tabMenus"
              :key="'tab-data-'+index"
              v-slot:[item.slot]
            >
              <component
                  style="background-color: #f6f6f6" v-if="item.component" :loading="loading" :item="item.item" :userInfo="userInfo" :ref="item.component" :is="item.component"></component>
            </template>

          </tab>
          <queue-highlight-gift></queue-highlight-gift>
        </section>



        <div class="home-fixed overflow"
          :class="{'home-fixed-size':goTopStatus}"
             v-if="!$store.state.config.mobile"
        >
          <a-popover placement="left"
                     v-for="(item,index) in fixedData"
                    >
            <template v-slot:content>
              <component v-if="item.is" :is="item.is"></component>
              <div v-else class="home-fixed-content">{{item.label}}</div>
            </template>
            <aside
                class="home-fixed-item center cursor-pointer"
                @click="trigger(item,index)"
            >
              <i class="monochromatic-live-icon">{{item.icon}}</i>
            </aside>
          </a-popover>
        </div>

      </section>

      <!--  功能   -->
      <div class="home-mobile-control-wrap absolute center"
           v-if="$store.state.config.mobile"
           v-show="!loading&&(!activeItem || !activeItem.hide) && (controlShow || controlRole.like)"
      >
        <div class="home-mobile-control "
             :class="[!initLoading?'control-animate-show':'control-animate-hide']"
             v-show="controlShow"
        >
          <template
              v-for="(item,index) in controlData"
          >
            <aside
                :key="'control-'+index"
                class="center"
                v-if="controlRole[item.key]"
                @click="trigger(item,index)"
            >
              <img :src="item.icon" class="home-mobile-control-icon" />
              <div>{{item.label}}</div>
            </aside>
          </template>

        </div>
        <give v-if="controlRole.like" :item="likeItem"></give>
      </div>


      <!--   表情   -->
      <modal v-if="$store.state.config.mobile" v-model:value="expressionModal" >
        <expression :loading="loading"></expression>
      </modal>
      <!--   调查问卷   -->
      <modal v-if="$store.state.config.mobile" v-model:value="questionnaire" live>
        <div class="questionnaire-mobile flex screen">
          <header class="header-chat-header rowACenter">
            <div class="flex-1">Questionnaire investigation</div>
            <svg t="1627959408031" @click="toggleQuestionnaire" class="header-chat-close cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2050" width="200" height="200"><path d="M571.01312 523.776l311.3472-311.35232c15.7184-15.71328 15.7184-41.6256 0-57.344l-1.69472-1.69984c-15.7184-15.71328-41.6256-15.71328-57.34912 0l-311.3472 311.77728-311.35232-311.77728c-15.7184-15.71328-41.63072-15.71328-57.344 0l-1.69984 1.69984a40.0128 40.0128 0 0 0 0 57.344L452.92544 523.776l-311.35232 311.35744c-15.71328 15.71328-15.71328 41.63072 0 57.33888l1.69984 1.69984c15.71328 15.7184 41.6256 15.7184 57.344 0l311.35232-311.35232 311.3472 311.35232c15.72352 15.7184 41.63072 15.7184 57.34912 0l1.69472-1.69984c15.7184-15.70816 15.7184-41.6256 0-57.33888l-311.3472-311.35744z" p-id="2051" fill="#333333"></path></svg>
          </header>
          <div class="flex-1">
            <questionnaire v-if="questionnaireDraw" :url="item.url"></questionnaire>
          </div>
        </div>
      </modal>
      <!--   礼物   -->
      <modal v-if="$store.state.config.mobile" v-model:value="giftModal" live>
        <gift-tab :giftData="giftData" :rankData="giftRanking"></gift-tab>
      </modal>
      <!--   问卷   -->
      <modal class="modal-content-radius" title="问卷" v-model:value="bulletinBoardModal">
        <bulletin-board-target></bulletin-board-target>
      </modal>
      <!--   投票   -->
      <modal class="modal-content-radius" title="投票" v-model:value="voteModal">
        <vote ref="vote"></vote>
      </modal>
      <!--   公告详情   -->
      <modal class="modal-content-radius" title="公告" v-model:value="noticeModal">
        <scroll-view>
          <div class="home-notice-padding" v-html="noticeContent"></div>
        </scroll-view>
      </modal>
      <!--  公告   -->
      <notice></notice>
    </section>
  </scroll-view>
  <div v-else  class="screen center container-fail-modal"
    :class="{
    'container-fail-modal-mobile': $store.state.config.mobile
    }"
  >
    <svg t="1653289135464" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3691" width="200" height="200"><path d="M512 32c-264.788 0-480 215.212-480 480 0 265.915 215.212 480 480 480 265.915 0 480-214.085 480-480 0-264.788-214.085-480-480-480v0zM512 605.521c-18.028 0-32.676-14.647-32.676-31.55v-309.859c0-16.901 14.648-30.423 32.676-30.423 19.155 0 33.802 13.522 33.802 30.423v309.859c0 16.901-14.648 31.55-33.802 31.55zM512 781.296c-18.028 0-31.55-13.522-31.55-30.423v-59.719c0-16.901 13.522-29.296 31.55-29.296s32.676 12.395 32.676 29.296v59.719c0 16.901-14.648 30.423-32.676 30.423z" p-id="3692" fill="#f40b34"></path></svg>
    <div>{{failText}}</div>
  </div>
  <!-- 加载 -->

</template>

<script>
import live from '../components/live';
import controlData from '../data/control';
import giftTab from '../components/gift-tab';
import expression from '../components/expression';
import mobile from '../components/mobile';
import bulletinBoardTarget from '../components/bulletin-board-target';
import fixedData from '../data/fixed';
import modal from '$components/modal';
import tabData from '../data/tab';
import mixins from '../mixins';
import questionnaire from '../components/questionnaire';
import richText from '../components/rich-text';
import { scrollView,vImage,tab } from '$components';
import ranking from '../components/ranking';
import give from '../components/give';
import videoList from '../components/video-list';
import vote from '../components/vote';
import notice from '../components/notice';
import expertIntroduction from '../components/expert-introduction';
import {
  Popover,
  Icon,
    Switch,
    Modal,
    Spin
} from 'ant-design-vue';
import Chat from "@/pages/home/components/chat/src/main";
import Gift from "@/pages/home/components/gift/src/main";
import login from '../components/login';
import VLoading from "$components/loading/src/main";
import QueueGift from "@/pages/home/components/live/components/queue-gift/src/main";
import QueueHighlightGift from "@/pages/home/components/live/components/queue-highlight-gift/src/main";
export default {
  name: "home",

  data(){
    return {
      controlData,
      tabData,
      fixedData
    }
  },

  mixins,

  components:{
    QueueHighlightGift,
    QueueGift,
    VLoading,
    Gift,
    vote,
    Chat,
    live,
    scrollView,
    vImage,
    login,
    [Popover.name]:Popover,
    [Switch.name]:Switch,
    aIcon:Icon,
    [Modal.name]:Modal,
    [Spin.name]:Spin,
    tab,
    modal,
    questionnaire,
    mobile,
    giftTab,
    expression,
    richText,
    bulletinBoardTarget,
    ranking,
    give,
    videoList,
    notice,
    expertIntroduction
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
