// 状态变更
import status from './status';
// 样式提供表
import style from './style';
// size 获取
import size from './size';
// 标签资源
import el from './el';
// 图标
import icon from './icon';
// 视频
import src from './src';
// 引入视频播放
import video from './video';

export default <LibMixins>[status,style,size,el,icon,src,video];
