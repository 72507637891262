<template>
  <section class="screen flex" >
    <div class="flex-1 relative">

      <div class="absolute absolute-screen center" v-if="loading || start && status === constStatus.loading">
        <a-spin size="large"></a-spin>
      </div>
      <div class="absolute chat-empty absolute-screen center" v-else-if="start&&messageDataOrder.length <=0 && status === constStatus.none">
        <img src="../images/empty.png" class="chat-empty-icon" />
        <div class="chat-empty-text">暂无消息</div>
      </div>
      <scroll-view
        ref="scroll"
        :behavior="$store.state.config.mobile ? '':'none'"
        @scroll-lower="getHistory"
      >
<!--        @scroll-upper="getHistory"-->
        <div class="overflow"
             :class="[!loading?'chat-animate-show':'chat-animate-hide']"
        >

          <template
              v-for="(pItem) in messageTopDataOrder"
          >
            <component

                v-for="(item) in messageTopTime[pItem]"
                :key="'chat-'+item.item.id+'-top'"
                :isMy="item.item.visitor_id === userInfo.hash_id"
                :is="item.type"
                :top="true"
                :item="item.item"
                :type="item.messageType"
            ></component>
          </template>
          <template
              v-for="(pItem) in messageDataOrder"
          >
            <template
              v-if="!messageTopTime[pItem] || messageTime[pItem] && messageTime[pItem].length - messageTopTime[pItem].length > 0"
            >
              <template
                  v-for="(item) in messageTime[pItem]"
              >
                <component
                    :key="'chat-'+item.item.id"
                    :isMy="item.item.visitor_id === userInfo.hash_id"
                    :is="item.type"
                    v-if="!messageTopData[item.item.id]"
                    :item="item.item"
                    :type="item.messageType"
                ></component>
              </template>

            </template>

          </template>

          <div class="chat-loading-height center" ref="header" v-if="!start">
            <a-spin v-show="status === constStatus.loading"></a-spin>
            <div v-show="status === constStatus.success" @click="getHistory">点击加载更多</div>
            <div v-show="status === constStatus.none">我也是有底线的</div>
            <div v-show="status === constStatus.fail" class="chat-fail-text" @click="getHistory">加载失败,点击重试</div>
          </div>
          <div v-else class="scroll-footer-fixed"></div>
        </div>
      </scroll-view>
    </div>
    <footer class="chat-footer rowACenter">
      <div v-if="$store.state.config.mobile"
           :class="{'expression-button-disabled':loading || taboo}"
           @click="!taboo&&toggleExpressionModal()" class="expression-icon-wrap center">
        <svg class="expression-icon cursor-pointer" viewBox="0 0 1024 1024" p-id="2877" width="200" height="200"><path d="M510.10048 67.60448c-248.13056 0-449.28 201.14944-449.28 449.28s201.14944 449.28 449.28 449.28 449.28-201.14944 449.28-449.28S758.23104 67.60448 510.10048 67.60448zM270.11072 386.35008c0-33.13664 26.86464-59.99616 60.00128-59.99616s59.99616 26.85952 59.99616 59.99616-26.8544 60.00128-59.99616 60.00128C296.97536 446.35136 270.11072 419.49184 270.11072 386.35008zM508.71808 735.0784c-83.34848 0-151.61856-64.57856-157.47072-146.45248 106.27072-1.73568 231.75168 0.6912 314.94656 0C660.34176 670.4896 592.07168 735.0784 508.71808 735.0784zM690.0992 446.35136c-33.14176 0-60.00128-26.85952-60.00128-60.00128 0-33.13664 26.85952-59.99616 60.00128-59.99616s59.99104 26.85952 59.99104 59.99616S723.24096 446.35136 690.0992 446.35136z" p-id="2878"></path></svg>
      </div>
      <a-popover v-else placement="topLeft" trigger="click"
                 :visible="expressionModal"
                 @visibleChange="handleClickChange"
      >
        <template v-slot:content>
          <expression :loading="loading"></expression>
        </template>
        <div :class="{'expression-button-disabled':loading}" class="expression-icon-wrap center">
          <svg class="expression-icon cursor-pointer" viewBox="0 0 1024 1024" p-id="2877" width="200" height="200"><path d="M510.10048 67.60448c-248.13056 0-449.28 201.14944-449.28 449.28s201.14944 449.28 449.28 449.28 449.28-201.14944 449.28-449.28S758.23104 67.60448 510.10048 67.60448zM270.11072 386.35008c0-33.13664 26.86464-59.99616 60.00128-59.99616s59.99616 26.85952 59.99616 59.99616-26.8544 60.00128-59.99616 60.00128C296.97536 446.35136 270.11072 419.49184 270.11072 386.35008zM508.71808 735.0784c-83.34848 0-151.61856-64.57856-157.47072-146.45248 106.27072-1.73568 231.75168 0.6912 314.94656 0C660.34176 670.4896 592.07168 735.0784 508.71808 735.0784zM690.0992 446.35136c-33.14176 0-60.00128-26.85952-60.00128-60.00128 0-33.13664 26.85952-59.99616 60.00128-59.99616s59.99104 26.85952 59.99104 59.99616S723.24096 446.35136 690.0992 446.35136z" p-id="2878"></path></svg>
        </div>
      </a-popover>
      <div class="flex-1 expression-input-wrap aCenter">
        <input v-if="taboo" disabled placeholder="您已被禁言" class="screen expression-input" />
        <form class="screen" v-else-if="$store.state.config.mobile" @submit.prevent="sendMessage">
          <input ref="input" :disabled="loading" v-model="message" :maxlength="maxLength" placeholder="我来说几句..." class="screen expression-input" />
        </form>
        <input v-else :disabled="loading" v-model="message" @keydown.enter="sendMessage" :maxlength="maxLength" placeholder="我来说几句..." class="screen expression-input" />
      </div>
      <div class="button expression-button expression-button-disabled"
           v-if="taboo"
      >禁言</div>
      <div @click="sendMessage" class="button expression-button"
        :class="{'expression-button-disabled':loading}"
           v-else
      >发送</div>
    </footer>
  </section>
</template>

<script>
import {
  scrollView
} from '$components';
import expression from '../../expression';
import components from '../components';
import {Popover,Spin} from "ant-design-vue";
import modal from '$components/modal';
import mixins from '../mixins';
import props from '../props';
import queueGift from '../../live/components/queue-gift';
import QueueHighlightGift from "@/pages/home/components/live/components/queue-highlight-gift/src/main";
export default {
  name: "chat",
  inject:['toggleExpressionModal'],
  components:{
    QueueHighlightGift,
    scrollView,
    ...components,
    expression,
    modal,
    [Popover.name]:Popover,
    [Spin.name]:Spin
  },
  mixins,
  props
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
