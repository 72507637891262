import {FunctionTrigger} from "$mixins/trigger/class";

export default [
    // {
    //     icon:'',
    //     // icon:'<path d="M256.003008 341.329323c0-141.182341 114.806118-255.996992 255.988459-255.996992 141.182341 0 255.996992 114.814651 255.996992 255.996992 0 141.173808-114.814651 255.996992-255.996992 255.996992s-255.996992-114.823184-255.996992-255.996992m723.874161 629.018742c-41.949374-157.139487-152.540074-280.018043-291.409909-337.404035 98.592975-59.903296 164.862063-168.019359 164.862062-291.623241C853.312256 153.128867 700.183389 0 511.982934 0s-341.329323 153.128867-341.329323 341.329323c0 123.595348 66.260555 231.711411 164.904729 291.614707-138.878368 57.428659-249.469069 180.264549-291.444042 337.404035a42.649099 42.649099 0 0 0 30.207645 52.172187 42.708832 42.708832 0 0 0 52.257519-30.207645C175.278623 810.008616 333.740761 682.658645 512 682.658645s336.75551 127.358504 385.446138 309.671028a42.580833 42.580833 0 0 0 52.223386 30.207646 42.649099 42.649099 0 0 0 30.207645-52.180721" p-id="5909" fill="#448aff"></path>',
    //     label:'立即登录'
    // },
    // {
    //     icon:'',
    //     is:'mobile'
    //     // icon:'<path d="M702.699388 60.945047 321.284238 60.945047c-57.463044 0-104.022314 46.591131-104.022314 104.018052l0 693.454702c0 57.426921 46.560293 104.019075 104.022314 104.019075l381.416174 0c57.463044 0 104.022314-46.592155 104.022314-104.019075L806.722726 164.963099C806.722726 107.537202 760.163456 60.945047 702.699388 60.945047zM477.317197 95.617731l69.349233 0c9.582632 0 17.336541 7.754614 17.336541 17.33583 0 9.582239-7.753909 17.336854-17.336541 17.336854l-69.349233 0c-9.581609 0-17.336541-7.754614-17.336541-17.336854C459.980656 103.372346 467.735588 95.617731 477.317197 95.617731zM511.992325 920.077116c-24.482581 0-44.324277-19.840884-44.324277-44.322461 0-24.480554 19.84272-44.322461 44.324277-44.322461 24.481557 0 44.324277 19.84293 44.324277 44.322461C556.317626 900.237255 536.473882 920.077116 511.992325 920.077116zM737.373493 771.736603c0 9.582239-7.753909 17.33583-17.336541 17.33583L303.946674 789.072433c-9.582632 0-17.337564-7.753591-17.337564-17.33583L286.60911 182.299953c0-9.582239 7.754932-17.33583 17.337564-17.33583l416.090278 0c9.582632 0 17.336541 7.754614 17.336541 17.33583L737.373493 771.736603z" p-id="11620" fill="#448aff"></path>',
    // },
    // {
    //     icon:'',
    //     is:'share'
    //     // icon:'<path d="M860.2 673.4c-66.8 0-121.4 35.8-146.8 91.3L390.6 627.3c18.8-29.2 29.2-64.9 29.2-103.5 0-16-1.9-32-5.6-47l206.1-160c27.3 20.7 62.1 32.9 100.7 32.9 93.2 0 162.8-69.6 162.8-161.8S814 26 720.9 26 559 95.6 559 187.8c0 23.5 4.7 45.2 12.2 64l-190 145.9c-37.6-56.5-99.7-96-170.3-96C95.2 301.7 2 394.8 2 510.6s93.2 208.9 208.9 208.9c43.3 0 83.7-11.3 116.7-32l370.7 158.1C703 933.1 770.8 998 860.2 998c93.2 0 161.8-69.6 161.8-161.8s-69.6-162.8-161.8-162.8zM720.9 93.7c46.1 0 93.2 34.8 93.2 93.2s-34.8 93.2-93.2 93.2-93.2-46.1-93.2-93.2 47.1-93.2 93.2-93.2z m-510 556.2c-69.6 0-128-58.3-128-128s58.3-128 128-128 128 58.3 128 128c-1 69.6-58.4 127.9-128 128z m649.3 278.5c-58.3 0-93.2-34.8-93.2-93.2s46.1-93.2 93.2-93.2c47 0 93.2 34.8 93.2 93.2-1 58.4-35.8 93.2-93.2 93.2z" p-id="13580" fill="#448aff"></path>',
    // },
    {
        icon:'',
        // icon:'<path d="M796.422846 524.478323 537.312727 265.185862c-6.368176-6.39914-14.688778-9.471415-22.976697-9.407768-1.119849-0.096331-2.07972-0.639914-3.19957-0.639914-4.67206 0-9.024163 1.087166-13.023626 2.879613-4.032146 1.536138-7.87163 3.872168-11.136568 7.135385L227.647682 524.27706c-12.512727 12.480043-12.54369 32.735385-0.032684 45.248112 6.239161 6.271845 14.432469 9.407768 22.65674 9.407768 8.191587 0 16.352211-3.135923 22.624056-9.34412L479.1356 363.426421l0 563.712619c0 17.695686 14.336138 31.99914 32.00086 31.99914s32.00086-14.303454 32.00086-31.99914L543.13732 361.8576l207.91012 207.73982c6.240882 6.271845 14.496116 9.440452 22.687703 9.440452s16.319527-3.103239 22.560409-9.311437C808.870206 557.277355 808.902889 536.989329 796.422846 524.478323z" p-id="14441" fill="#448aff"></path><path d="M864.00258 192 160.00258 192c-17.664722 0-32.00086-14.336138-32.00086-32.00086S142.337858 128 160.00258 128l704 0c17.695686 0 31.99914 14.336138 31.99914 32.00086S881.698266 192 864.00258 192z" p-id="14442" fill="#448aff"></path>',
        label:'返回顶部',
        trigger: new FunctionTrigger(function (){
            this.$refs.scroll&& this.$refs.scroll.scrollToStart();
        })
    }
]
