import {VerificationType} from "$utils/verification";

export default function () {

    return [
        {
            placeholder:'Your name',
            icon: require('../images/user.png'),
            key:'user_name',
            value:'',
            rules:'Please enter user name'
        },
        {
            placeholder: ' Your email',
            icon: require('../images/mailbox.png'),
            key:'user_email',
            value: '',
            rules:{
                [VerificationType.empty]:'Please enter email address',
                [VerificationType.email]:'Please enter the correct email format'
            }
        }
    ]

}
