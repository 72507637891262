
export default <Config>{

    baseApiURL:'',

    // baseURL:'https://test_www.qdtech.ai/',
    baseURL:'http://bizconfapi.yunkust.com/',

    app:undefined,

    useRoutesName:[],

    GET:{},

    createdGET(){
        // 获取url
        let url = location.search;

        let arg = url.split('?').pop();

        let GET = {};

        arg.split('&').map((item)=>{
            if (item) {
                let arg = (item || '').split('=');
                GET[arg[0]] = arg[1];
            }
        });

        this.GET = GET;
    },

    register(vm,plugs){

        this.app = vm;

        this.app.$store.dispatch('installConfig');
        this.app.$store.commit('initializationUser');



    }

}
