<template>
  <!-- 最外层模块 -->
  <section class="screen">
    <aside
      class="screen center"
      :style="{paddingBottom:occupancySize}"
      v-if="start && status !== constStatus.success"
    >
      <svg v-if="status === constStatus.loading" class="flat-list-loading" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
          <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
        </path>
      </svg>

      <slot v-else-if="status === constStatus.none" name="empty">
        <empty-item  :color="emptyColor" :empty-text="emptyText"></empty-item>
      </slot>


    </aside>
    <slot v-else-if="!start" name="page">
      <scroll-view
          class="screen"
          @scroll-lower="pagingLoading"
          :lowerThreshold="lowerThreshold"
          :security-size="securitySize"
      >
        <slot name="layout" :data="data">

          <div class="row wrap">
            <slot
                name="item"
                v-for="(item,index) in data"
                :item="item"
                :index="index"
            ></slot>
          </div>
        </slot>
        <!--  底部加载  -->
        <footer v-if="usePaging" class="center flat-list-footer overflow">
          <svg v-if="status === constStatus.loading" class="flat-list-footer-loading" viewBox="0 0 100 100" enable-background="new 0 0 0 0" xml:space="preserve">
              <path fill="#fff" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">
                <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>
            </path>
         </svg>
          <div @click="reload()" class="screen flat-list-footer-fail center cursor-pointer" v-else-if="status === constStatus.fail">加载错误,点击重试</div>
        </footer>
        <!--  底部占位  -->
        <div v-if="occupancySize" :style="{height:occupancySize}"></div>
      </scroll-view>
    </slot>

  </section>

</template>

<script>
import {
  scrollView
} from '$components';
import emptyItem from '$components/empty-item';
import props from '../props';
import mixins from '../mixins';
export default {
  name: "flat-list",
  props,
  mixins,
  components:{
    scrollView,
    emptyItem
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
