export default {

    // 头部数据
    data:{
        type:Array,
        default:function ():tabData {
            return ['找陪玩','语聊厅']
        }
    },

    // 是否使用懒加载
    lazy:{
        type:Boolean,
        default: true
    },

    // 当前使用的value
    value:{
        type: Number
    },

    // 默认展示
    defaultValue:{
        type:Number,
        default:0
    },

    // 是否使用滑动
    touchMove:{
        type:Boolean,
        default: false
    },

    // 过渡时间
    speed:{
        type: Number,
        default:300
    },

    // 自动设置高度
    autoHeight:{
        type:Boolean,
        default: false
    },

    // 自定义设置高度但是需要保持 auto
    autoHeightFixed:{
        type: Boolean,
        default: false
    }

}
