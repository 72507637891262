<template>
<!--  v-show="triggerStatus"-->
  <div class="room-gift-container overflow absolute">

    <template v-for="(useQueueItem) in useQueueObject" :key="useQueueItem">
      <template v-if="useQueueItem">
        <div class="screen jCenter">
          <div
              :class="['animate-'+useQueueItem._animate.name,useQueueItem._animate.config.animated ? 'animated center':'']"
              :key="useQueueItem._id"
              class="animate-type"
              :style="{'animation-duration': useQueueItem._animate.config.duration+'ms'}"
          >
            <v-image mode="center" background-color="transparent" class="queue-gift-image" :src="useQueueItem.gift.src" />
          </div>
        </div>
      </template>
    </template>

  </div>
</template>

<script>
import mixins from '../mixins';
import VImage from "$components/v-image/lib/main";
export default {
  name: "queue-gift",
  components: {VImage},
  mixins
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
