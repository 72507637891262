import Status from './const/status';
export default <LibMixins>{

    data(){
      return {
          // 当前状态
          status: Status.default,
          // 使用延迟key
          status_async_key:'statusAsync',
          // 是否使用唯一值
          status_unique: true,
          // 请求的名称
          status_fetch_key:'fetch',
          // 常量
          constStatus: Status
      }
    },

    methods:{

        // 设置状态变更
        setStatus(status,data?:any){

            if (status !== this._status) {
                clearTimeout(this._statusTime);
                this._status = status;
                if (!this.start && this[this.status_async_key] && status === Status.loading) {
                    this._statusTime = setTimeout(()=>{
                        this.status = this._status;
                    },this[this.status_async_key]);
                } else {
                    this.status = status;
                }

                this.triggerChangeStatus && this.triggerChangeStatus(status,data);
            }
        }

    },
    created(){
        this._status = Status.none;
    }

}
