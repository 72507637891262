<template>
  <div class="mobile-container rowACenter"
    :class="['mobile-'+type]"
  >
    <img src="../images/code.png" class="mobile-icon" />
    <div class="flex-1 mobile-content">
      <div class="mobile-title">{{item.title}}</div>
      <div>{{item.content}}</div>
    </div>
  </div>
</template>

<script>
import props from '../props';
import typeData from '../data/type';
export default {
  name: "mobile",
  props,

  computed:{
    item(){
      return typeData[this.type] || typeData.default;
    }
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
