import instructions,{Instructions} from './instructions/index';

import config from "./config";

import constName from './const';

import axios from "axios";

import {LibRequestStatus} from "$utils/request/const/request";

export { LibRequestStatus,InstructionsMessageType,InstructionsCacheType,InstructionsTypes } from "$utils/request/const/request";

// 创建实例化工具
const requestContent = axios.create(config[constName.mode.default]);

const request = function (data:LibRequestOptions) {
    return new Promise<ResponseData>(function (resolve, reject) {

        // 创建请求上下文

        let requestContext =Instructions.createRequestContext({
            requestData: data,
            reject,
            resolve,
            status:LibRequestStatus.loading
        });

        // 如果前期校验通过触发
        if (instructions.trigger(requestContext)) {
            return requestContent(requestContext.requestData).then(function (data){
                return requestContext.success(data);
            }).catch(function (fail){
                return requestContext.fail(fail);
            });
        } else {
            // 否则释放上下文
            // @ts-ignore
            requestContext = null;
        }

    });

}

request.uploadFile = function (data:LibRequestOptions) {

    // @ts-ignore
    data.file = true;

    data.header ={
        'content-type':'multipart/form-data'
    }

    return request(data);

}


request.install = function (app:any) {
    app.config.globalProperties.$request = request;
};

export default request;
