<template>
  <scroll-view
      :scrollY="$store.state.config.mobile"
  >
    <div
        class="bulletin-board-mobile bulletin-board-mobile-submit"
    >
      <!--  视图为空  -->
      <div class="bulletin-empty center"
           v-if="statusConst.empty === status"
      >
        <img src="../../bulletin-board-target/images/empty.png" class="bulletin-empty-icon" />
        <div>暂无投票</div>
      </div>
      <!--  视图  -->
      <div v-else-if="statusConst.submit === status || statusConst.success === status" class="bulletin-submit">
        <div class="bulletin-subject-group vote-group"
          :class="{
            'vote-text-group': data.vote_type === 1
          }"
        >
          <div
            v-for="(item,index) in questions"
            :key="index"
            class="vote-item"
          >
            <div class="vote-item-title"><span class="vote-item-title-margin" style="color: #9E9E9E">{{index+1}}/{{questions.length}}</span> {{item.question_name}}<span style="color: #448AFF">（{{item.question_multi===1?'单选':'多选,最多选'+(item.max_question_num)+'项'}}）</span></div>
            <div class="vote-item-group rowACenter wrap">
              <div
                v-for="(cItem,cIndex) in item.items"
                :key="index+'-'+cIndex"
                class="vote-target-item flex"
                @click="triggerSelect(item,cIndex)"
              >
                <v-image v-if="data.vote_type === 2" :src="cItem.image" class="vote-target-item-image">
                  <div class="vote-target-item-title absolute">{{cItem.item_name}}</div>
                </v-image>
                <div class="flex-1 rowACenter relative vote-target-item-content">
                  <div class="flex-1 overflow">
                    <div class="vote-line-1" v-if="data.vote_type !== 1">{{cItem.vote_sum + (item.value && item.value.indexOf(cIndex) >= 0 ? 1 : 0)}}票</div>
                    <div v-else>{{cItem.item_name}}</div>
                  </div>
                  <template v-if="statusConst.submit === status">
                    <a-radio v-if="item.question_multi===1" :value="index" :checked="item.value && item.value.indexOf(cIndex) >= 0" class="vote-radio"></a-radio>
                    <a-checkbox v-else :disabled="(!item.value || item.value.indexOf(cIndex) < 0) && item.value && item.value.length >= item.max_question_num"
                                :value="index"
                                :checked="item.value && item.value.indexOf(cIndex) >= 0"
                                class="vote-checkbox"
                    ></a-checkbox>
                  </template>
                  <template v-else>
                    <div v-if="item.value && item.value.indexOf(cIndex) >= 0" class="vote-target-title">已投票</div>
                    <a-radio v-else-if="item.question_multi===1" :disabled="true" class="vote-radio"></a-radio>
                    <a-checkbox v-else :disabled="true"
                                class="vote-checkbox"
                    ></a-checkbox>
                  </template>

                  <div class="absolute vote-target-item-disabled"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a-button class="bulletin-button button-active " style="display: block"
                  :class="{
          'bulletin-button-disabled':!submitStatus,
          'bulletin-button-active': submitStatus
        }"
                   :loading="submitLoading" @click="submit" type="primary">{{statusConst.success === status ? '已投票':'投票'}}</a-button>
      </div>
    </div>
  </scroll-view>
</template>

<script>
import mixins from '../mixins';
import ScrollView from "$components/scroll-view/src/main";
import { Button,Radio,Checkbox } from 'ant-design-vue';
import VImage from "$components/v-image/lib/main";
export default {
  name: "vote",
  components: {
    VImage,
    ScrollView,
    [Button.name]:Button,
    [Radio.name]:Radio,
    [Radio.Group.name]:Radio.Group,
    [Checkbox.name]:Checkbox,
    [Checkbox.Group.name]:Checkbox.Group
  },
  mixins
}
</script>

<style scoped src="../style.scss" lang="scss"></style>
