<template>
  <div class="container-screen"
    style="background-color: #f6f6f6"
  >
    <scroll-view>
      <div class="introduction-padding">
        <aside
          class="introduction-item row"
          v-for="(item,index) in data"
          :key="index"
          :class="{
             'introduction-hidden':!show
          }"
        >
          <img :src="item.expert_logo" class="introduction-item-image" @click="goDetail(item)" />
          <div class="flex-1 overflow relative" >
            <div class="introduction-item-title rowACenter" @click="goDetail(item)">
              <div class="flex-1">{{item.expert_name}}</div>
              <img src="../images/more.png" class="introduction-item-more" />
            </div>
            <div class="introduction-item-content relative"
              :class="{'introduction-item-content-no-display':!item.status || !item.isOpen}"
                 v-html="item.expert_introduction"
            >
            </div>
            <div v-if="item.isOpen"><span
                       class=" introduction-item-open"
                       @click="item.status=!item.status"
                       :class="{
                     'introduction-item-pack': item.status
                   }"
            >
                <span>{{item.status?'收起':'展开'}}</span>
                <svg class="introduction-item-open-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3181" width="200" height="200"><path d="M490.666667 601.6L797.866667 298.666667l59.733333 59.733333-302.933333 302.933333-59.733334 64-59.733333-59.733333L128 358.4 187.733333 298.666667l302.933334 302.933333z" fill="#448AFF" p-id="3182"></path></svg>
              </span></div>
            <div :ref="'content-'+index" class="absolute introduction-item-content introduction-top-width introduction-hidden">
              <div>{{item.expert_introduction}}<span></span></div>
            </div>
          </div>
        </aside>
      </div>
    </scroll-view>
  </div>
</template>

<script>
import ScrollView from "$components/scroll-view/src/main";
import utils from '@/utils/tool/utils';
import props from '../props';
export default {
  name: "expert-introduction",
  components: {ScrollView},
  props,
  data(){
    return {
      show:false,
      data:[]
    }
  },
  methods:{

    initData(){
      this.data.map((item,index)=>{
        let content = this.$refs['content-'+index];
        let childrenContent = content.getElementsByTagName('div')[0];
        let burying = childrenContent.getElementsByTagName('span')[0];

        let offsetHeight = content.offsetHeight;
        let offsetWidth = content.offsetWidth;
        let lastRowProportion = burying.offsetLeft / offsetWidth;

        // 内容
        let contentHeight = childrenContent.offsetHeight;

        item.height = contentHeight;

        // 获取行
        let row = Math.ceil(contentHeight/offsetHeight);

        // 设置最大行
        let maxRow = 3;

        item.isOpen = row > maxRow;


        if (item.isOpen) {
          let avgLength = item.expert_introduction.length / (row - 1 + lastRowProportion);

          item.defaultContent = item.expert_introduction.substr(0,Math.floor(avgLength * 2.7))+'...';

        } else {
          item.defaultContent = item.expert_introduction;
        }

      });
      this.show = true;
    },

    goDetail(item){
      return utils.routerExpertDetails(item.expert_url);
    }

  },
  mounted() {
    this.initData();
  },
  created() {
    this.data = this.item.data;
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
