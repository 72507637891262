enum InstructionsWhere {
    has='has',
    skip='skip',
    custom='custom',
    hasCustom='hasCustom'
}

enum InstructionsCacheSync {
    first
}

enum LibRequestStatus {
    loading,
    success,
    fail
}

enum InstructionsTypes {
    start='start',
    end='end',
    all='all'
}

enum InstructionsCacheType {
    storage,
    memory,
    all
}

enum InstructionsMessageType {
    // 自适应
    auto,
    // 仅提示成功
    success,
    // 仅提示其他
    other

}

export {
    LibRequestStatus,
    InstructionsTypes,
    InstructionsWhere,
    InstructionsCacheSync,
    InstructionsCacheType,
    InstructionsMessageType
}