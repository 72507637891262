<template>
  <div class="screen ranking-container">
    <scroll-view>
      <aside v-for="(item,index) in rankData"
        :key="'ranking-'+index"
             class="ranking-item rowACenter"
             @click="openGiftDetail(item)"
      >
        <div class="ranking-item-number">{{(index + 1 < 10)?'0'+(index+1):index+1}}</div>
        <div class="ranking-item-line"></div>
        <img :src="item.logo" class="ranking-item-icon" />
        <div class="flex-1 ranking-item-title">
          <div class="line-1">{{item.name}}</div>
        </div>
        <div class="ranking-send-number">{{item.count}}</div>
      </aside>
    </scroll-view>
  </div>
</template>

<script>
import props from '../props';
import {
  scrollView
} from '$components';
export default {
  name: "gift-ranking",
  props,
  components:{
    scrollView
  },
  inject:['openGiftDetail']
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
