import constName from '../../const';

export default {

    mode:{
        [constName.mode.default] :{
            successCode:[200],
            pageOption:{
                page:'page',
                pageSize:'page_limit'
            },
            user:{
                token:'login_token'
            },
            failMessage:{
                default: 'Busy service',
                timeout: 'Network busy'
            }
        } as RequestConfig,
        [constName.mode.main]:{
            successCode: [1]
        } as RequestConfig
    },

    // 获取配置
    getTargetConfig:function (mode:string | undefined,key:keyof RequestConfig) {

        if (mode === undefined) {
            mode = constName.mode.default as unknown as string;
        }

        if (this.mode[mode] && this.mode[mode][key]) {
            return this.mode[mode][key];
        }
    }

}
