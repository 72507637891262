export default {

    item:{
        type:Object,
        default:function () {
            return {};
        }
    }

}
