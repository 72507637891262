<template>
  <div class="screen relative overflow">
    <div class="absolute absolute-screen">
        <iframe :src="url"
                class="overflow questionnaire-content"
                v-if="url"
                width="100%"
                height="100%"
                frameborder="0"
        ></iframe>
    </div>

  </div>
</template>

<script>
import {
  scrollView
} from '$components';
import props from '../props';
export default {
  name: "questionnaire",
  props,
  components:{
    scrollView
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
