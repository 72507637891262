<template>
  <section class="screen center" :class="['empty-item-'+icon]">
    <img v-if="config" :src="config.icon" :style="{height:svgSize,width:svgSize}" />
    <svg
         :style="{height:svgSize,width:svgSize}"
         v-else
         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7927" width="200" height="200"><path d="M544 0C281.6 0 64 217.6 64 480S281.6 960 544 960 1024 742.4 1024 480 806.4 0 544 0z m0 896C313.6 896 128 710.4 128 480S313.6 64 544 64 960 249.6 960 480 774.4 896 544 896z m-128-512h-128c-19.2 0-32-12.8-32-32s12.8-32 32-32h128c19.2 0 32 12.8 32 32s-12.8 32-32 32z m416-32c0 19.2-12.8 32-32 32h-128c-19.2 0-32-12.8-32-32s12.8-32 32-32h128c19.2 0 32 12.8 32 32z m-128 384c0 19.2-12.8 32-32 32s-32-12.8-32-32c0-51.2-44.8-96-96-96S448 684.8 448 736c0 19.2-12.8 32-32 32s-32-12.8-32-32C384 646.4 454.4 576 544 576s160 70.4 160 160z" :fill="color" p-id="7928"></path></svg>
    <div class="empty-title"
      :style="{fontSize:fontSize,color:color}"
    >{{emptyText}}</div>
  </section>
</template>

<script>
import props from '../props';
import unit from '$utils/unit/unit';
import iconData from '../data/icon';
export default {
  name: "empty-item",

  computed:{
    svgSize(){
      if (this.config) {
        return unit.unitRem(this.config.size || this.size);
      } else {
        return unit.unitRem(this.size);
      }

    },
    fontSize(){

      if (this.config) {
        return unit.unitRem((this.config.size || this.size) * (this.config.ratio || this.ratio));
      } else {
        return unit.unitRem(this.size * this.ratio);
      }
    },
    config(){
      return iconData[this.icon];
    }
  },

  props,
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
