import unit from '$utils/unit/unit';
import {FlatListReload} from "$components/flat-list/const";
import Status from "$mixins/status/const/status";

export default <LibMixins>{

    data(){
        return {
            status_async_key:'async_target_time',
            start:true,
            data:[],
            hasPaging:true
        }
    },

    computed:{
        occupancySize(){
            return unit.unitRem(this.occupancy);
        },
        usePaging(){
            return this.paging && this.hasPaging
        }
    },

    methods:{

        fetch(must:boolean = false){

            if (this._status === this.constStatus.loading && !must) return;

            if (this.start) {
                this.setStatus(Status.default);
            }

            if (
                this._status === this.constStatus.fail
            ) {
                this.setAsyncTime(0);
            } else {
                this.setAsyncTime(this.asyncTime);
            }

            return this.statusFetch(must,this.config);

        },

        triggerChangeStatus(status,data:Array<any>){


            if (data && (status === this.constStatus.success || status === this.constStatus.none)) {

                data = data || [];

                let hasPage = data.length >= this.config.pageSize;

                this.setData(data,this.config.page === 1);

                // 设置首次加载 结束
                if (data.length>0 && this.config.page === 1) {
                    this.setStart(false);
                }

                if (!this.start) {
                    // 增加分页
                    this.config.page++;
                }

                if (this.hasPaging !== hasPage) {
                    this.hasPaging = hasPage;
                }

                // 如果没有分页了 标注
                if (!hasPage) {
                    return this.setStatus(this.constStatus.none,false);
                }

            }

        },

        // 设置data
        setData(data:Array<any>,replace:boolean=false){

            // 构建索引
            if (this.indexes) {

                if (this.dataIndexes === undefined) this.dataIndexes = [];
                // 获取索引
                let resultData = [];
                for (let i=0,count=data.length;i<count;i++) {
                    if (this.dataIndexes.indexOf(data[i][this.indexes]) < 0) {
                        this.dataIndexes.push(data[i][this.indexes]);
                        resultData.push(data[i]);
                    }
                }
                if (resultData.length !== data.length) {
                    data = resultData;
                }
            }

            if (replace) {
                this.data = data;
            } else {
                this.data.push(...data);
            }

            return this.$emit('changeData',this.data);

        },

        pagingLoading(){
            if (this.usePaging && this._status !== this.constStatus.loading) {
                return this.reload();
            }
        },

        setAsyncTime(time:Number){
            if (this.async_target_time !== time) {
                this.async_target_time = time;
            }
        },

        setStart(boolean:Boolean){
            if (this.start !== boolean) {

                if (boolean && this.data.length > 0) {
                    this.data = [];
                }

                this.start = boolean;
            }
        },

        reload(status:FlatListReload = FlatListReload.next){

            let must:boolean = false;

            if (status === FlatListReload.start) {
                this.setStart(true);
            }

            if (
                status === FlatListReload.start
                ||
                status === FlatListReload.mustReload
                ||
                status === FlatListReload.reload
            ) {
                // 重置分页
                this.config.page = 1;
                if (this.indexes) {
                    this.dataIndexes = [];
                }
                this.hasPaging = true;
            }

            if(
                status === FlatListReload.start
                ||
                status === FlatListReload.mustReload
            ) {
                must = true;
            }


            return this.fetch(must);

        }

    },
    mounted() {
        this.setAsyncTime(this.asyncTime);
        this.first && this.fetch();
    },

    created() {
        this.config = {
            page:1,
            pageSize: this.pageSize
        }
        // 控制为 无状态
        this.status = this.constStatus.default;
        this._status = this.constStatus.default;
    }

}
