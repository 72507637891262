<template>
  <section class="expression-container rowWrap"
    :class="{'expression-container-mobile': $store.state.config.mobile}"
  >
    <aside v-for="(item,key) in expressionData"
      class="expression-item center"
           :class="{'cursor-pointer':!loading}"
           :key="key"
           @click="sendExpression(item)"
    >
      <img :src="item.icon" />
    </aside>
  </section>
</template>

<script>
import expressionData from '../data/expression';
import mixins from '../mixins';
import props from '../props';
export default {
  name: "expression",
  data(){
    return {
      expressionData
    }
  },
  mixins,
  props
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
