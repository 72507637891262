import unit from "$utils/unit/unit";
import {Queue} from "$utils/tool/queue";
import Throttle from '$utils/tool/throttle';
import {PlayerMessage} from "$utils/class/Player/const";

export default {

    inject:['getSocket'],

    data(){
      return {
          column:[],
          width:0,
          columnHeight:unit.unitPx(45),
          config:{}
      }
    },
    methods:{

        // 安装配置文件
        installConfig(){

            // 获取标记
            let el = this.$refs.bullet;

            /* 设置尺寸 */
            this.width = el.offsetWidth;
            this.config.height = el.offsetHeight;
            /* 设置尺寸 */

            /* 设置管道数量(取最小的管道数量) */
            let column = Math.floor(this.config.height / this.columnHeight);
            this.config.column = column > this.column.maxColumn ? this.column.maxColumn : column;
            /* 设置管道数量(取最小的管道数量) */
        },

        // 查找最近空管道
        searchEmptyColumn(createdTime){
            this.searchInvalidTrigger();
            let minTime = undefined;
            for (let i=0;i<this.config.column;i++) {

                if (this._column_last[i] === undefined) {
                    return  i;
                } else if (this._column_last[i]) {
                    let config = this._column_last[i];
                    let diff = createdTime - config.createdTime;
                    if (config.duration && diff > config.endTime) {
                        return  i;
                    } else if (config.endTime)  {
                        let useDiff = config.endTime - diff;
                        if (minTime === undefined || minTime > useDiff) {
                            minTime = useDiff;
                        }
                    }
                }

            }

            if (minTime===undefined) {
                minTime = 16;
            }



            return {
                time:minTime
            };
        },

        // 查找失效的
        searchInvalid(createdTime=0){
            createdTime = createdTime || +new Date();
            return this.column.map((item,index)=>{
                if (item && createdTime > (item.createdTime + item.duration)) {
                    this.column[index] = undefined;
                    this._column_last[index] = undefined;
                }

            });
        },

        // 触发查找失效
        searchInvalidTrigger(){
            return this.searchInvalid();
        }

    },

    mounted() {
      this.installConfig();

    },

    created() {

        // 聊天对象
        this.agoraRTMIM = this.getSocket();

        this.config = {
            // 列(俗称管道)
            column:0,
            // 最大列(俗称管道)
            maxColumn:5,
            // 排队对象
            queue: new Queue(),
            // 每一个动画时长
            duration:3000,
            // 最大移动多大像素才可以被使用
            minScroll:15,
        }

        this.searchInvalidThrottle = new Throttle({
            first:false,
            call:this,
            handle: this.searchInvalidTrigger,
            type:'queueWait',
            delay:4000
        });

        this.searchInvalidTrigger = this.searchInvalidThrottle.supper;
    },

    beforeUnmount() {
        this.searchInvalidThrottle && this.searchInvalidThrottle.destroy();
    }

}
