import Status from './const/status';
export default <LibMixins>{

    data(){
        return {
            // 当前状态
            status: Status.none,
            // 使用延迟key
            status_async_key:'statusAsync',
            // 是否使用唯一值
            status_unique: true,
            // 请求的名称
            status_fetch_key:'fetch',
            // 常量
            constStatus: Status
        }
    },

    methods:{

        // 设置状态变更
        setStatus(status,data?:any){

            if (status !== this._status) {
                clearTimeout(this._statusTime);
                this._status = status;
                if (this[this.status_async_key] && status === Status.loading) {
                    this._statusTime = setTimeout(()=>{
                        this.status = this._status;
                    },this[this.status_async_key]);
                } else {
                    this.status = status;
                }

                this.triggerChangeStatus && this.triggerChangeStatus(status,data);
            }
        },

        // 开启请求
        statusFetch:function (must:boolean=false,data:any) {

            // 如果当前状态为请求中就结束本次请求
            if (this._status === Status.loading && !must) return;

            let unique = '';
            if (this.status_unique) {
                unique = new Date().getTime() + '-' + Math.floor(Math.random() * 10000);
                this._statusUseUnique = unique;
            }

            this.setStatus(Status.loading);

            return this.$emit(this.status_fetch_key,{
                success:(data?:any)=> {
                    if (!this.status_unique || unique === this._statusUseUnique) {
                        return this.setStatus(Status.success,data);
                    }
                    // @ts-ignore
                    unique = null;
                },
                fail:()=> {
                    if (!this.status_unique || unique === this._statusUseUnique) {
                        return this.setStatus(Status.fail);
                    }
                    // @ts-ignore
                    unique = null;
                },
                none:()=>{
                    if (!this.status_unique || unique === this._statusUseUnique) {
                        return this.setStatus(Status.none);
                    }
                    // @ts-ignore
                    unique = null;
                },
                unique,
                data
            });

        }

    },
    created(){
        this._status = Status.none;
    }

}
