import calculation from './calculation';

export default <ImageApi>{

    /*
    * 缩放模式，不保持纵横比缩放图片，使图片的宽高完全拉伸至填满 image 元素
    * */
    scaleToFill:function():ImageExport{
        return {
            width: '100%',
            height: '100%',
            top:0,
            left:0
        }
    },

    center:function (params:ImageParams):ImageExport {

        let width = 0;
        let height = 0;
        let containerWidth = params.container.width - 2;
        let containerHeight = params.container.height - 2;

        // 如果全部小于该尺寸触发
        if (
            params.container.width >= params.image.width
            &&
            params.container.height >= params.image.height
        ) {
            width = params.image.width;
            height = params.image.height;
        } else {

            // 否则 选择最大的边进行 重新设置
            let isHeightMax = params.image.height > params.image.width;

            // 获取
            let ratio = calculation.getRatio(params.image);

            // 对长边进行设置
            if (isHeightMax) {
                height = containerHeight;
                width = calculation.getRatioWidth(height,ratio);
            } else {
                width = containerWidth;
                height = calculation.getRatioHeight(width,ratio);
            }

        }

        return {
            width: width,
            height:height,
            left: calculation.getLeft(width,params.container),
            top: calculation.getTop(height,params.container)
        }
    },

    /*
    * 缩放模式，保持纵横比缩放图片，可以完整地将图片显示出来。
    * */
    cover:function (params:ImageParams):ImageExport {

        // 获取
        let ratio = calculation.getRatio(params.image);

        // 设置宽度
        let width = params.container.width;

        // 计算高度
        let height = calculation.getRatioHeight(width,ratio);


        // 如果高度小于 视图高度
        if (height <  params.container.height) {
            // 计算差
            let diff = params.container.height - height;
            // 重新设置高度
            height = params.container.height;
            // 增加宽度比例
            width += calculation.getRatioWidth(diff,ratio);
        }

        return {
            width: width,
            height:height,
            left: calculation.getLeft(width,params.container),
            top: calculation.getTop(height,params.container)
        }

    },

    /* 缩放模式，保持纵横比缩放图片，使图片的长边能完全显示出来。也就是说，可以完整地将图片显示出来。但是不已中间为布局 */
    topCover:function (params:ImageParams):ImageExport {

        // 获取
        let ratio = calculation.getRatio(params.image);

        // 设置宽度
        let width = params.container.width;

        // 计算高度
        let height = calculation.getRatioHeight(width,ratio);


        // 如果高度小于 视图高度
        if (height <  params.container.height) {
            // 计算差
            let diff = params.container.height - height;
            // 重新设置高度
            height = params.container.height;
            // 增加宽度比例
            width += calculation.getRatioWidth(diff,ratio);
        }

        return {
            width: width,
            height:height,
            left: calculation.getLeft(width,params.container),
            top: 0
        }

    },

    /* 缩放模式，保持纵横比缩放图片，只保证图片的短边能完全显示出来。也就是说，图片通常只在水平或垂直方向是完整的，另一个方向将会发生截取。 */
    aspectFill:function (params:ImageParams):ImageExport{

        // 计算比例
        let ratio = calculation.getRatio(params.image);
        // 容器
        let container = params.container;
        // 宽度是否为短边
        let widthShort = container.width <= container.height;

        let width = widthShort ? container.width : calculation.getRatioWidth(container.height,ratio);

        let height = widthShort ? calculation.getRatioHeight(container.width,ratio) : container.height;

        return {
            width,
            height,
            left: calculation.getLeft(width,container),
            top: calculation.getTop(height,container)
        }
    },

    /* 缩放模式，保持纵横比缩放图片，使图片的长边能完全显示出来。也就是说，可以完整地将图片显示出来。 */
    aspectFit:function (params:ImageParams):ImageExport{

        // 计算比例
        let ratio = calculation.getRatio(params.image);
        // 容器
        let container = params.container;
        // 宽度是否为长边
        let widthLogin = container.width >= container.height;
        // 宽度
        let width = widthLogin ? container.width : calculation.getRatioWidth(container.height,ratio);
        // 高度
        let height = widthLogin ? calculation.getRatioHeight(container.width,ratio) : container.height;

        return {
            width,
            height,
            left: calculation.getLeft(width,container),
            top: calculation.getTop(height,container)
        }
    },

    /* 缩放模式，宽度不变，高度自动变化，保持原图宽高比不变 */
    widthFix:function (params:ImageParams):ImageExport{

        return {
            width: params.container.width,
            height: calculation.getRatioHeight(params.container.width,calculation.getRatio(params.image)),
            left: 0,
            top: 0
        }
    },

    /* 缩放模式，高度不变，宽度自动变化，保持原图宽高比不变 */
    heightFix:function (params:ImageParams):ImageExport{
        return {
            width: calculation.getRatioWidth(params.container.height,calculation.getRatio(params.image)),
            height: params.container.height,
            left: 0,
            top: 0
        }
    }

}
