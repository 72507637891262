import {
    PlayerMessage
} from '$utils/class/Player/const';
enum RoomMessageType{
    // 普通消息
    message='chat-message',
    // 时间
    time='chat-time'
}

let RoomChatType = {
    // 礼物或者普通消息
    [PlayerMessage.gift]: RoomMessageType.message,
    [PlayerMessage.richText]:RoomMessageType.message,
    [PlayerMessage.expression]: RoomMessageType.message,
    [PlayerMessage.chat]: RoomMessageType.message,
    [PlayerMessage.time]: RoomMessageType.time
}

export {
    PlayerMessage,
    RoomChatType
}
