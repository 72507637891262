<template>
  <section class="login-container screen jCenter aEnd"
           :class="{'login-container-mobile': $store.state.config.mobile}"
    :style="{backgroundImage:'url('+ ($store.state.config.mobile?mobileBackground:background)+')'}"
  >
    <div class="login-modal">
      <header class="login-title">ACCOUNT LOGIN</header>
      <section class="login-input-wrap overflow">
        <aside
            v-for="(item,index) in inputData"
            class="login-input-item rowACenter"
            :key="'input-'+index"
        >
          <img :src="item.icon" class="login-icon" />
          <div class="flex-1">
            <input v-model="item.value" @keydown.enter="submit" :placeholder="item.placeholder" :maxlength="item.maxLength || 50" />
          </div>

        </aside>
      </section>

      <a-button :loading="buttonLoading" @click="submit" type="primary" class="login-button">Login</a-button>

    </div>
  </section>
</template>

<script>
import mixins from '../mixins';
import {
  Button
} from 'ant-design-vue';
export default {
  name: "login",
  mixins,
  components:{
    [Button.name]:Button
  }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
<style lang="scss">
.login-container {
  .ant-btn-primary,.ant-btn-primary:hover, .ant-btn-primary:focus {
    background-color: $main !important;
    border-color: $main !important;
  }
}

</style>
