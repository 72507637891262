import {Status} from '../../bulletin-board-target/const/index';

import {PlayerMessage} from '@/utils/class/Player/const/index';

import popup from '$utils/tool/popup';

export default {

    data(){
        return {
            statusConst:Status,
            status: Status.empty,
            data: undefined,
            questions:[],
            submitLoading:false,
            noticeList:{}
        }
    },

    inject:['getSocket','updateHeight','toQuestionnaire','addLoginCallback','closeQuestionnaire'],

    watch:{
      data:{
          handler(){
              this.createSubject(this.data);
          },
          immediate: true
      }
    },

    computed:{
      submitStatus(){
          if (this.status === Status.success) return false;
          return this.questions.some(function (item) {
              return item.value && item.value.length > 0;
          })
      }
    },

    methods:{

        // 提交调查问卷
        submit(){

            if (this.submitLoading || !this.submitStatus) return;

            if (this.questions.every(function (item) {
                return item.value && item.value.length > 0;
            })) {
                return this.submitData();
            } else {
                return popup.$confirm({
                    title:'您有题目未完成，确认提交?',
                    content:'提交后不可修改'
                }).then(()=>{
                    return this.submitData();
                })
            }
        },

        submitData(){
            if (this.submitLoading) return;
            this.submitLoading = true;
            this.player.triggerVote(this.questions.map((item,index)=>{
                return {
                    [item.vote_id]:(index + 1) +'|'+ (item.value ? item.value.map((item)=> item+1).join(',') : '')
                }
            })).then((response)=>{
                this.submitLoading = false;
                if (response.status === 'y') {
                    this.setStatus(Status.success);
                } else {
                    if (response.flag === 106 || response.flag === 100) {
                        this.setStatus(Status.success);
                    } else {
                        popup.$toast(response.info);
                    }

                }
            });


        },

        // 获取调查问卷
        getVoteInfo(){
            this.player.getVoteInfo().then((data)=>{
                if (data.questions) {
                    this.data = data;
                } else {
                    this.setStatus(Status.empty);
                }
            }).catch(()=>{
                this.setStatus(Status.empty);
            })
        },

        setStatus(status:Status){
            if (this.status !== status) {
                this.status = status;
            }
        },

        // 关闭
        closeQuestion(){
            clearTimeout(this.questionsTime);
            this.closeQuestionnaire();
            popup.$toast('投票已截止');
        },

        // 倒计时
        countDown(){
            this.questionsTime = setTimeout(()=>{
                if (this.data) {
                    if (this.player.getVoteTimeOver(this.data)) {
                        this.closeQuestion();
                    } else {

                        this.countDown();
                    }
                } else {
                    clearTimeout(this.questionsTime);
                }
            },1000);
        },

        createSubject(data){
            clearTimeout(this.questionsTime);
            if (data && data.questions) {
                this.submitLoading =false;
                let beforeQuestions = this.questions;
                this.questions = data.questions.map((item,index)=>{
                    let value = undefined;
                    if (item.id && beforeQuestions[index] && beforeQuestions[index].id === item.id) {
                        value = beforeQuestions[index].value
                    }
                    return {
                        ...item,
                        value
                    }
                });

                this.countDown();

                if (data.voted) {
                    this.setStatus(Status.success);
                } else {
                    this.setStatus(Status.submit);
                }
            } else {
                this.setStatus(Status.empty);
            }

        },

        triggerSelect(item,value){

            if (this.status !== Status.submit) return false;

            if (!item.value) item.value = [];

            if (item.question_multi===1) {
                if (item.value.indexOf(value) < 0) {
                    item.value = [value];
                }
            } else {
                let index = item.value.indexOf(value);
                if (index >= 0) {
                    item.value.splice(index,1);
                } else {
                    if (item.value.length < item.max_question_num) {
                        item.value.push(value);
                    }
                }
            }


        }
    },

    mounted() {
        this.player = this.getSocket();

        this.player.on(PlayerMessage.vote, (data)=> {
            if (data.status === false) {
                this.data = undefined;
            } else {
                this.getVoteInfo();
            }
        });

        this.addLoginCallback(()=> this.getVoteInfo());

    }

}
