import { emitNames } from '../enum/emit';
import {ScrollConfig} from "@/components/scroll-view/types/on";

export default <LibMixins>{

    methods:{
        emitScrollUpper:function (axis:'x'|'y') {
            return this.$emit('scroll-upper',axis);
        },
        emitScrollLower:function (axis:'x'|'y') {
            return this.$emit('scroll-lower',axis);
        },
        emitScroll:function (config:ScrollConfig) {
            return this.$emit('scroll',config);
        }
    },

    created() {
        // x是否执行了到达底部
        this._lowerThresholdXStatus = false;

        // y是否执行了到达底部
        this._lowerThresholdYStatus = false;

        // x是否执行了到达顶部
        this._upperThresholdXStatus = false;

        // y是否执行了到达顶部
        this._upperThresholdYStatus = false;
    }

}