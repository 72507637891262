export default {

    data(){
        return {
            expressionModal:false
        }
    },

    provide(){
      return {
          hideExpressionModal:()=> this.hideExpressionModal()
      }
    },

    methods:{
        handleClickChange(value){
            this.expressionModal = value;
        },
        hideExpressionModal(){
            if (this.expressionModal) {
                this.expressionModal = false;
            }
        }
    }

}
