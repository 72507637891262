export default  class Limit {

    // 多长时间作为一次节点 默认 10s
    maxTime:number = 10

    // 限定时间内 最大执行多少次
    maxCount:number = 5

    // 当前开始记录时间
    startTime:number = 0;

    // 记载结束时间
    endTime:number = 0;

    // 记录执行次数
    count:number = 0;

    // 执行
    trigger(){

        // 当前时间
        let nowTime = Math.floor(new Date().getTime()/1000);

        // 如果当前时间段超过了 重置
        if (this.endTime - nowTime <0 ) {
            this.startTime = nowTime;
            this.endTime = nowTime + this.maxTime;
            this.count = 0;
        }

        // 执行记录执行次数 +1
        this.count++;
        // 判断是否超过
        return this.count < (this.maxCount + 1);

    }





}
