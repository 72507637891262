export default {

    item:{
        type:Object,
        default:function (){
            return {}
        }
    },

    type:{
        type:[String,Number],
        default:'0'
    }

}
