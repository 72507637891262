export default {

    min:{
        type:Number,
        default:1
    },

    max:{
        type:Number,
        default:999
    },

    value:{
        type:Number,
        default:1
    },

    type:{
        type:String,
        default:''
    },

    disabled:{
        type:Boolean,
        default:false
    }

}
