export default {

    swiperOption:{
        type:Object
    },

    value:{
        type: [Number,String],
        default: 0
    },

    pagination:{
        type:Boolean,
        default: false
    }

}