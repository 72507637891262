export default {

    value:{
        type: Boolean,
        default: false
    },

    color:{
        type:String,
        default:'#4590d8'
    },

    defaultColor:{
        type:String,
        default:'#fff'
    },

    disabled:{
        type:Boolean,
        default: false
    }

}