<template>
  <div class="chat-message row"
    :class="{
    'chat-message-my':isMy,
    'chat-message-admin-container': item.is_admin
  }"

  >
    <img class="chat-message-avatar" :src="item.avatar" />
    <div class="flex-1 chat-message-content">
      <div class="rowACenter chat-message-header">
        <div class="chat-message-nickname line-1">{{item.username}}</div>
        <div class="chat-message-admin center" v-if="item.is_admin">
          {{ item.title || '官方' }}
        </div>
        <div class="chat-message-top center" v-if="top">
          <svg t="1629370482864" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2347" width="200" height="200"><path d="M855.866698 106.426915c0 23.451108-19.010983 42.463114-42.463114 42.463114L203.435319 148.890029c-23.451108 0-42.463114-19.010983-42.463114-42.463114l0 0c0-23.451108 19.010983-42.463114 42.463114-42.463114l609.968266 0C836.855716 63.963801 855.866698 82.974783 855.866698 106.426915L855.866698 106.426915zM511.826038 264.35069c-23.451108 0-42.463114 19.010983-42.463114 42.463114l0 609.968266c0 23.451108 19.010983 42.463114 42.463114 42.463114l0 0c23.451108 0 42.463114-19.010983 42.463114-42.463114L554.289152 306.812781C554.288129 283.361673 535.277146 264.35069 511.826038 264.35069L511.826038 264.35069zM541.961382 233.023194c-16.582677-16.582677-42.517349-17.534352-57.927317-2.124384L171.253362 543.67849c-15.408945 15.408945-14.458293 41.343617 2.124384 57.926294l0 0c16.582677 16.582677 42.517349 17.534352 57.926294 2.124384L544.085766 290.949488C559.494711 275.540544 558.544059 249.605871 541.961382 233.023194L541.961382 233.023194zM481.696834 233.023194c-16.582677 16.582677-17.534352 42.517349-2.124384 57.926294l312.780703 312.780703c15.408945 15.408945 41.343617 14.458293 57.926294-2.124384l0 0c16.582677-16.582677 17.534352-42.517349 2.124384-57.926294L539.623128 230.897787C524.214183 215.488843 498.27951 216.440517 481.696834 233.023194L481.696834 233.023194z" p-id="2348" fill="#fb8f30"></path></svg>
        </div>
      </div>
      <div class="row">
        <div  class="chat-message-target relative jCenter">
          <img class="chat-message-image"
               :class="['chat-message-image-'+(!item.src && item.message ? 'img' :item.size ||'default')]"
               :src="item.src || item.message"
               v-if="type === PlayerMessage.expression"
          />
          <div v-else-if="type === PlayerMessage.gift" class="rowACenter wrap chat-message-gift">
            送出 <span>{{item.gift.number}}</span> 个 {{item.gift.name}}<img :src="item.gift.src" />
          </div>
          <div v-else-if="type === PlayerMessage.richText" v-html="item.richText"></div>
          <div v-else-if="item.messageArray">
            <div v-for="(cItem,cIndex) in item.messageArray" :key="cIndex">{{cItem}}</div>
          </div>
          <div v-else>{{item.message}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImage from "$components/v-image/lib/main";
import props from '../props';
import { PlayerMessage } from '$utils/class/Player/const';
export default {
  name: "chat-message",
  components: {VImage},
  data(){
    return {
      PlayerMessage
    }
  },
  props,
  // created() {
  //   console.log(this.item);
  // }
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
