<template>
  <div class="absolute absolute-screen overflow bullet-container"
       :class="{'bullet-mobile': $store.state.config.mobile}"
       ref="bullet">
    <template
        v-for="(item,index) in column"
        :key="'column-'+index"
    >
      <div
          v-for="(cItem) in item"
          :key="cItem.item.id"
          :style="{
            height:columnHeight+'px',
            top: index * columnHeight+'px',
            'transition': cItem.duration+'ms linear',
            'transform':'translateX('+( !cItem.duration ? '100%' : ((width+cItem.width) * -1)+'px' )+')'
          }"
          :class="{
             'bullet-chat-roll-running': cItem.duration
          }"
          class="absolute jCenter bullet-chat-roll"
          :ref="cItem.item.id+''"
      ><span :style="{color:cItem.item.target_color}">{{cItem.item._introduce}}</span></div>
    </template>
  </div>
</template>

<script>
import mixins from '../mixins';
import props from '../props';
export default {
  name: "bullet-chat",
  mixins,
  props
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
