import {
    notification,
    message,
    Modal
} from 'ant-design-vue';

type PopupToastOptions = Record<string, any>;

export default <Record<string, LibMixinsMethod | any>>{

    $toast:function (option:PopupToastOptions | string,type='info') {
        return this._handleNotice(type,option,message);
    },

    $confirm(content){
        return new Promise<void>(function (resolve, reject){
            return Modal.confirm({
                okText: '确认',
                cancelText: '取消',
                ...content,
                onOk:()=>{
                    resolve();
                },
                onCancel:()=>{
                    reject();
                },
                class:'custom-confirm'
            })
        });
    },

    // 通知框
    $success:function (option:PopupToastOptions,target) {
        return this._handleNotice('success',option,target);
    },
    $fail:function (option:PopupToastOptions,target) {
        return this._handleNotice('error',option,target);
    },
    $info:function (option:PopupToastOptions,target) {
        return this._handleNotice('info',option,target);
    },
    $warning:function (option:PopupToastOptions,target) {
        return this._handleNotice('warning',option,target);
    },

    // 处理
    _handleNotice(type,option:PopupToastOptions,target){
        let resultData:any = option;
        if (target === undefined) {
            resultData = {};
            if (typeof option === 'string') {
                resultData={message:option}
            } else {
                resultData= {
                    ...option,
                    duration: option.duration ? option.duration / 1000 : 4,
                    message: option.title,
                    class:'custom-message'
                };
            }
        }

        target = target || notification;




        return  target[type](resultData);
    },

    // 处理基本的options
    _handleOption(option:PopupToastOptions):Record<string, any>{
        let resultOption:PopupToastOptions;

        if (typeof option === 'string') {
            resultOption={title:option}
        } else {
            resultOption=option;
        }
        return  resultOption;
    }

}
