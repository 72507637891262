import inputData from '../data/input';
import verification from '$utils/verification';
import constName from '$utils/request/const/index';
import {InstructionsCacheType} from "$utils/request";
import popup from "$utils/tool/popup";
export default {

    data(){
        return {
            background: require('../images/background.png'),
            mobileBackground: require('../images/mobile-background.png'),
            inputData:inputData(),
            buttonLoading:false
        }
    },

    inject:['loginSuccess'],

    methods:{

        submit(){

            verification.verificationPromise(this.inputData,true).then((data)=>{

                let stringify = JSON.stringify(data);

                if (this.reponseData && stringify === this.reponseData.data) {
                    return popup.$toast(this.reponseData.msg);
                }

                this.$request({
                    url:'index/index/login',
                    data:data,
                    mode: constName.mode.main,
                    message: true,
                    failMessage:true,
                    next:({status}) => this.buttonLoading = status
                }).then((data)=>{
                    if (data.isSuccess) {
                        this.reponseData = undefined;

                        // 设置用户信息
                        this.$store.commit('setUserInfo',data.data);
                        // 设置登录成功
                        return this.loginSuccess(true,true);
                    } else {
                        this.reponseData = {
                            msg: data.msg,
                            data: stringify
                        }
                    }

                });

            });

        }

    }

}
