<template>
  <section class="relative live-container overflow"
           id="player-container"
           :class="{
      'live-container-mobile': $store.state.config.mobile
      }"
  >
    <aside class="absolute live-screen" id="player">

    </aside>
    <aside
        class="absolute live-screen live-screen-background"
        :style="{
          'background':'url('+background+')'
        }"
        v-if="loading"
    >
      <img class="live-image screen"
               src="../images/banner.jpeg"
           v-if="!background"
      />
    </aside>
    <bullet-chat v-if="barrage" :user-info="userInfo"></bullet-chat>
<!--    <queue-gift></queue-gift>-->

    <!--  倒计时 -->
    <div class="countdown-time rowCenter" v-if="countDownId && !liveStatus"
      :class="{
      'countdown-time-mobile': $store.state.config.mobile
      }"
    >
      <span>{{countDown.msg}}：</span>
      <div class="rowACenter"
        v-for="(item,index) in countDownIdFormat"
           :key="'count-'+index"

      >
        <div class="countdown-time-time center" :class="{
             'countdown-time-fixed': item.formatAdd
           }">{{item.formatAdd ? item.value>=10?item.value:'0'+item.value :item.value}}</div>
        <div class="countdown-time-format">{{item.format}}</div>
      </div>
    </div>
  </section>

</template>

<script>
import vImage from '$components/v-image';
import queueGift from '../components/queue-gift';
import bulletChat from '../components/bulletChat';
import props from '../props';
import mixins from '../mixins';
export default {
  name: "live",
  mixins,
  components:{
    vImage,
    queueGift,
    bulletChat
  },
  props
}
</script>

<style scoped lang="scss" src="../style.scss"></style>
