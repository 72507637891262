export default {
    0:{
        color:'#FAD152',
        icon: require('../images/ranking/1.png')
    },
    1:{
        color:'#D5D5D5',
        icon: require('../images/ranking/2.png')
    },
    2:{
        color:'#E6B368',
        icon: require('../images/ranking/3.png')
    },
    default:{
        color:'#333'
    }
}
