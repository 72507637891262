export default {

    // 默认缓存多久
    storageTime: 7 * 24 * 60 * 60,

    // 用户信息
    user:{
        email:'',
        now_time:0,
        id:0,
        name:'',
        avatar:'',
        login_time:10
    },

    // 缓存的key
    storageKey:'user',

    // 校验唯一值
    stringify:'',

    // 是否登录
    isLogin(){
        return this.user && this.user.login_token;
    },

    // 获取用户id
    uid(){
      return this.user && this.user.id;
    }


}
