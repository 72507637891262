class QueueItem {

	data;

	next;

	prev;

	constructor(data) {
		this.data=JSON.parse(JSON.stringify(data));
	}
	setNext(data,set = true) {

		// 设置对象的上指针 指向 this
		if (set) {
			data.setPrev(this,false);
		}

		// 设置自身的下指针为该对象
		if (this.next) {
			// 更新自己的下指针的上指针 为 该对象
			this.next.setPrev(data);
		}

		// 更新自己的指针
		this.next = data;

	}
	setPrev(data,set = true) {

		// 设置另一方对象的下指针 为 自身
		if (set) {
			data.setNext(this,false);
		}

		// 设置自己的上指针为 该对象
		if (this.prev) {
			this.prev.setNext(data);
		}

		// 更新自身指针
		this.prev = data;

	}
}

class Queue{

	private first=undefined;

	private last=undefined;

	constructor(data=null) {
		if (data) {
			this.first = data;
			this.last = data;
		}
	}

	push(data){

		if (!this.first) {
			this.first = data;
		}

		if (this.last) {

			this.last.setNext(data);
		}

		this.last = data;
	}

	unshift(data) {
		if (this.first) {
			this.first.setPrev(data);
		}
		this.first = data;
		if (!this.last) {
			this.last = data;
		}
	}

	// 移除第一个
	shift(){
		if (this.first) {
			let first = this.first;

			this.first = this.first.next;

			return first;
		} else {
			return null;
		}
	}

	// 移除最后一个
	pop(){
		if (this.last) {
			let last = this.last;

			this.last = this.last.prve;

			return last;
		} else {
			return null;
		}
	}

	splice(data,nextData) {
		if (data) {
			data.setNext(nextData);
		}
	}

	map(callback) {

		let data = this.first;
		let index = 0;
		while (data) {
			callback(data.data,index);
			index++;
			data = data.next;
		}

	}
}



export {
	Queue,
	QueueItem
}

