export default {

    icon:{
      type:String,
      default:''
    },

    size:{
        type: Number,
        default: 80
    },

    color:{
        type:String,
        default:'rgba(255,255,255,0.5)'
    },

    // 文字和 size的比例
    ratio:{
       type:Number,
       default:0.2
    },

    // 为空的提示文本
    emptyText:{
        type:String,
        default:'暂无数据'
    }

}
