import encryption from '../encryption/encryption';
export default {

	storage:localStorage,

	setItem:function(key: string, value: any, time?: number){

		time = time || -1;

		let data = {
			data:value,
			time: time>0?this.get_time() + time:time,
		};

		//设置 缓存
		this.storage.setItem(key,this.stringify(data));

	},

	removeItem:function(key: string){
		this.storage.removeItem(key);
	},

	getItem:function (key: string) {

		let data = this.storage.getItem(key);

		if (!data) {
			return null;
		}else {

			if (typeof data === 'string'){
				data = this.parse(data);
			} else {
				return data;
			}

			if (!data)  return null;

			// @ts-ignore
			if (data['time']){
				// @ts-ignore
				if (data['time'] < 0){
					// @ts-ignore
					return data.data;
					// @ts-ignore
				} else if (this.get_time() < data['time']) {
					// @ts-ignore
					return data.data;
				} else {
					this.removeItem(key);
					return null;
				}

			} else {
				// @ts-ignore
				return data.data;
			}

		}

	},

	get_time:function(){
		// @ts-ignore
		return Math.floor(Date.parse(new Date())/1000)
	},

	stringify:function (data: any) {
		return encryption.encode(JSON.stringify(data));
	},

	parse:function (data: string) {
		try {
			data = encryption.decode(data);
			data = JSON.parse(data);
			return data;
		} catch (e) {
			return null;
		}
	}

}
