enum FlatListReload {

    // 从头开始
    start,

    // 从第一页开始
    reload,

    mustReload,

    // 向下继续
    next

}

export {FlatListReload};