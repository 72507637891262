import {Status, SubjectType} from '../const';
// @ts-ignore
import data from '../data.json';
import config from '../data/config';

import verification from '@/utils/verification/index';

import {PlayerMessage} from '@/utils/class/Player/const/index';

import popup from '$utils/tool/popup';

import {DateFormat} from '$utils/tool/date';

import user from '@/config/user';

import $request from '$utils/request/index';
import {nextTick} from "vue";

export default {

    data(){
        return {
            statusConst:Status,
            status: Status.empty,
            data: undefined,
            subject:[],
            SubjectType,
            submitLoading:false,
            noticeList:{}
        }
    },

    inject:['getSocket','updateHeight','toQuestionnaire','addLoginCallback'],

    watch:{
      data:{
          handler(){
              this.createSubject(this.data);
          },
          immediate: true
      }
    },

    methods:{

        // 提交调查问卷
        submit(){

            if (this.submitLoading) return;

            verification.verificationPromise(this.subject,true).then(()=>{
                this.submitLoading = true;
                let data = this.data.columns.map((item)=>{

                    let resultItem = {
                        name: item.name,
                        type: item.type,
                        [item.type === SubjectType.question ? 'selects':'text']: item._config && item._config.format ? item._config.format(item.value) : (item.value || '')
                    };

                    if (!resultItem.text && item.allow_fill_by_self && item.allow_fill_by_self_value) {
                        resultItem.text = item.allow_fill_by_self_value;
                    }

                    return resultItem;
                });

                this.player.sendQuestion(data,this.data.question_id).then((data)=>{
                    if (data.errcode === 1000) {
                        this.setStatus(Status.success);
                    } else {
                        popup.$toast(data.msg);
                    }
                }).finally(()=>{
                    this.submitLoading = false;
                });

            });
        },

        // 获取调查问卷
        getQuestion(){

            this.player.getQuestion().then((data)=>{
                if (data.page_in[0]) {
                    this.data = data.page_in[0];
                    this.data.created_at = DateFormat(new Date(this.data.created_at),'YYYY-MM-DD hh:mm:ss');
                } else {
                    this.setStatus(Status.empty);
                }
            }).catch((fail)=>{
                console.log(fail);
                this.setStatus(Status.empty);
            })
        },

        setStatus(status:Status){
            if (this.status !== status) {
                this.status = status;
            }
        },


        createSubject(data){
            if (data && data.columns) {
                this.submitLoading =false;
                let subject = [];

                let subjectIndex = 0;
                (data.columns || []).map((item,index)=>{
                    if (config[item.type]) {
                        let itemConfig = config[item.type].where ? config[item.type].where(item) : config[item.type];

                        let result = item;
                        result._config =itemConfig;

                        if (result.must) {
                            result.rules = itemConfig.rules;
                        }

                        if (itemConfig.content !== false) {
                            subjectIndex++;
                        }
                        item.subjectIndex = subjectIndex;


                        result.key = subject.push(result) - 1;
                    } else if (item.type === 'nickname') {
                        item.value = user.user.name;
                    }
                });

                this.subject = subject;
                nextTick(()=> this.toQuestionnaire(data));

                // @ts-ignore
                this.setStatus(window.getTargetCookieKey('skip_signup_'+data.question_id) === '1' ? Status.success : Status.submit);
            } else {
                this.setStatus(Status.empty);
            }

        },
        changeTargetValue(parent,item){
            if (parent._config && parent._config.change) {
                return parent._config.change(parent,item,this);
            } else {
                parent.value = item;
            }
        }
    },

    mounted() {
        this.player = this.getSocket();

        try {
            this.noticeList = JSON.parse(localStorage.getItem('noticeList'));
        } catch (e) {

        }

        this.player.on(PlayerMessage.question, (data)=> {
            if (data.status === false) {
                this.data = undefined;
            } else {
                this.getQuestion();
            }
        });

        this.addLoginCallback(()=>{
            this.getQuestion();
        });

        // this.player.init(0).then(()=>{
        //     this.getQuestion();
        // })

    }

}
