import Throttle from '$utils/tool/throttle';
import {nextTick} from 'vue';
export default <LibMixins>{

    methods:{

        sendMessageScroll:function (async:boolean=true) {

            // 获取判断
            if (this.$refs.scroll && this.rangEndDistance >= this.$refs.scroll.getScrollEndDistance()) {

                if (async) {
                    this.scrollToEnd();
                } else {
                    this.scrollEnd();
                }
            }

        },

        scrollTo:function (y:any){

            let config = this.$refs.scroll.getScrollConfig();

            y = config.maxScroll - y;
            // y = y<=0?0:y;
            if (config.y === 0) {
                return this.$refs.scroll && this.$refs.scroll.scrollToDistance({y});
            }

        },

        scrollToEnd(){
            return this.scrollEnd();
        },

        scrollEnd(){
            nextTick(()=>{
                return this.$refs.scroll.scrollToStart();
            });
        }

    },
    created() {

        // 当在底部多少范围内，收到消息直接跳转到最底部
        this.rangEndDistance = 300;

        this.throttle = new Throttle({
            type:'queueWait',
            delay: 200,
            mustDelay:300,
            handle: this.scrollToEnd,
            call: this
        });

        this.scrollToEnd = this.throttle.supper;

    },

    beforeUnmount(){
        this.throttle && this.throttle.destroy();
    }

}
