import Socket from "./socket";
import {PlayerMessage, Status} from "$utils/class/Player/const";

export default class Player extends Socket{

    static status: Status = Status.none;

    static watch:Array<Record<string, any>> = [];

    static setStatus(status:Status){
        if (Player.status !== status) {
            Player.status = status;
        }
    }

    static trigger(key:string){
        Player.watch.map((item)=> item[key] && item[key]());
        Player.watch = [];
    }

    constructor() {
       super();
    }

    // 获取菜单栏
    getMenus(){
        // @ts-ignore
        return Mudu.Room.GetMenus();
    }

    init(roomId){

        return new Promise((resolve, reject)=>{

            if (Player.status === Status.success) {
                // @ts-ignore
                resolve();
            } else {
                Player.watch.push({
                    resolve,
                    reject
                });
                if (Player.status === Status.none) {

                    Player.setStatus(Status.loading);


                    // @ts-ignore
                    Mudu.Init(
                        // 频道id
                        roomId,
                        // 初始化完成的回调函数，无参数
                         (data)=> {
                             this.installSocketMessage();

                             // @ts-ignore
                             Mudu.MsgBus.On(
                                 // 事件名，值为Room.StreamEvent
                                 'Room.StreamEvent',

                                 // 事件处理函数，参数类型为object
                                 (data)=> {
                                     data = JSON.parse(data)

                                     let state = data.event == 1;

                                     if(this.player && this.player.setControls) {
                                         let playerState = this.player.getState();

                                         if (!state && playerState === 'playing') {
                                             this.remove();
                                             this.createPlayer( this.storageData,this.storageCallback);
                                         } else {
                                             return this.player.setControls(state);
                                         }
                                     }
                                 }
                             );
                             Player.trigger('resolve');
                             return Player.setStatus(Status.success);
                        },
                        function (e) {
                            Player.trigger('reject');
                            return Player.setStatus(Status.none);
                        }
                    )
                }


            }

        });


    }

    // 获取房间信息
    getRoomInfo(){
        // @ts-ignore
        return Mudu.Room || {};
    }

    // 获取直播间浏览量
    getViewNum(){
        if (Player.status === Status.success) {
            // @ts-ignore

            // @ts-ignore
            return Mudu.Room.GetViewNum();
        } else {
            return 0;
        }
    }

    // 播放器
    player:any;

    getThemes(){
        // @ts-ignore
        let Themes =Mudu.Room.GetThemes() || [];

        return Themes[0] || {};

    }

    storageCallback:any;
    storageData:any;

    storagePlayData:any='';

    // 获取直播状态
    getLiveStatus(){
        // @ts-ignore
        return !!Mudu.Room.GetLiveStatus()
    }

    // 创建直播间
    createPlayer(playData,callback){

        if (this.player === undefined) {

            this.storageData = playData;
            this.storageCallback = callback;

            let el = document.getElementById(playData.containerId);
            this.storagePlayData = playData;

            // @ts-ignore
            let isChannelLiving = !!Mudu.Room.GetLiveStatus() // Mudu.Room.GetLiveStatus() 获取当前的直播状态 类型为number: `1`为正在直播，`0`为不在直播

            let config = {
                // 必须，播放器容器ID，播放器会被添加到该DOM元素中
                // containerId: 'J_prismPlayer',

                // 非必需 boolean 控制播放器的ui展示, 默认为false; 根据播放视频的实际情况填写
                // @ts-ignore
                isLive: true,

                // 必须，播放器视频播放地址
                // @ts-ignore
                src: Mudu.Room.GetPlayAddr(),

                // 非必须，播放器海报图 string
                // @ts-ignore
                image: Mudu.Room.GetLiveImage(),

                // 非必须，播放器是否自动播放，默认false
                autoplay: isChannelLiving,

                // 非必须，播放器是否显示控制条，默认true
                controls: false,

                // 非必须，播放器是否循环播放, 默认false
                repeat: false,

                // 非必须，播放器宽度，单位为像素，默认为480
                width: el.offsetWidth,

                // 非必须，播放器高度，单位为像素，默认为270
                height: el.offsetHeight,

                // 以下x5Layer和x5Inline两个参数用于解决安卓微信、QQ、QQ浏览器的只能全屏播放的问题。参数仅对安装过tbs(腾讯浏览服务，一般安装过QQ浏览器后手机上会存在)手机生效(由于tbs的api限制，部分低版本的微信、QQ、QQ浏览器可能不会生效)，未安装tbs的安卓手机不会有只能全屏播放的问题。
                // x5Layer和x5Inline只能有一个被设置为true

                // 非必须，播放器在安卓微信、QQ、QQ浏览器中是否同层播放, 默认false  （注：同层播放时，页面document无法滚动(内部的dom元素可以)，如果播放器宽度小于浏览器宽度，两边将出现黑边）
                x5Layer: false,

                // 非必须，播放器在安卓微信、QQ、QQ浏览器中是否inline播放，默认false  （注：inline播放时，播放器始终处于z-index的最上层，因此无法在播放器上叠加元素）
                x5Inline: false,

                ...playData
            };
            // @ts-ignore
            this.player = new Mudu.Player(config);

            callback && callback(config);

            this.player.setControls(isChannelLiving);
        }


    }

    // 播放
    playVideo(item){

        return new Promise((resolve, reject)=>{
            if (this.player) {
                this.getVideoInfo(item.video_id).then((file)=>{
                    this.player.load([{
                        file,
                        isLive:false,
                        image: item.image
                    }]);
                    this.player.setControls(true);
                })
            } else {
                return reject();
            }

        });



    }

    // 获取
    getVideo(video_id){
        return new Promise( (resolve, reject)=> {
            this.getVideoInfo(video_id).then((data)=>{
                console.log(data);
            }).catch(reject);
        })
    }



    playLiveVideo(){
        if (this.player) {
            let state = this.player.getState();
            if (state !== 'playing') {
                return this.player.play();
            }
        }

    }

    setControls(status){
        this.player && this.player.setControls(status);
    }

    getUser(){
        // @ts-ignore
        return Mudu.Room.User.GetUser();
    }

//     // @ts-ignore
//     Mudu.MsgBus.On(
//         // 事件名，值为Comment.New
//     'User.Update.Over',
//
//     // 事件处理函数，参数为新的评论，类型为object
// (newComment)=> {
//     // console.log(newComment);
// }
// );
    // 登录
    login(name,avatar,assignId){
        // 需要在sdk 初始化成功后才能使用
        return new Promise((resolve)=>{
            console.log(assignId);
            // @ts-ignore
            Mudu.Room.User.Assign(name, avatar, assignId, (a)=>{
                resolve(a);
            });
        });

    }

    remove() {
        this.player && this.player.remove();
        this.player = undefined;
    }

}
