<template>
  <scroll-view
      :scrollY="$store.state.config.mobile"
  >
    <div class="rich-text-content"
         :class="{
          'rich-text-content-mobile': $store.state.config.mobile
         }"
         v-html="html"></div>
  </scroll-view>

</template>

<script>
import props from '../props';

import axios from "axios";
import ScrollView from "$components/scroll-view/src/main";
import { ref,inject,nextTick } from 'vue';
export default {
  name: "rich-text",
  components: {ScrollView},
  setup(props){

    const html = ref('');

    const updateHeight = inject('updateHeight');
    updateHeight();
    axios.get(props.item.rich_text_url).then((data)=>{
      html.value = data.data;
      nextTick(()=> updateHeight());
    });

    return {
      html
    };
  },
  props
}
</script>

<style scoped lang="scss">
.rich-text-content{
  font-size: 16px;
  line-height: 25px;
  padding-top: 24px;
  padding-bottom: 24px;
  min-height: 300px;
}
.rich-text-content-mobile{
  padding: 15px;
}
.rich-text-content::v-deep p {
  margin-bottom: 0;
}
.rich-text-content::v-deep *{
  max-width: 100%;
}
</style>
