export default {
    'default':{
        title:'Scan the code to enter the mobile viewing page',
        content:'Wechat scanning is recommended'
    },
    'weChat':{
        title:'Share with wechat friends or circle of friends',
        content:'Scan with wechat'
    }
}
